import { useNavigate, useParams } from 'react-router-dom';
import { ActionButtons } from 'components/ActionButtons/ActionButtons';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { Table } from 'components/Table/Table';
import { useJobs } from 'hooks/useJobs';
import { JobColumns } from 'components/Jobs/consts/columns';
import { JobsTableProps } from 'components/Jobs/consts/tableProps';
import { Alert, Form, Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Path } from 'routes';
import { JobForm } from 'components/Jobs/JobForm';
import { UploadCSVModal } from 'components/UploadCSVModal/UploadCSVModal';
import { AddEditJobPayload, Job, JobRecordResponse } from 'types/job';
import { useOrganizations } from 'hooks/useOrganizations';
import { useSkills } from 'hooks/useSkills';
import { AxiosError } from 'axios';
import { ValidationErrorResponse } from 'types/common';
import { formatErrors } from 'common/utils';

export const Jobs = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const [mappedCompanies, setMappedCompanies] = useState<Array<{ label: string, value: string }>>([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCSVModalOpen, setIsCSVModalOpen] = useState(false);
  const [form] = Form.useForm();
  const watchedValues = Form.useWatch([], form);
  const [isFormValid, setIsFormValid] = useState(false);
  const {
    jobs,
    isLoading,
    params,
    setParams,
    total,
    jobData,
    setSelectedJobId,
    deleteJobs,
    updateJob,
    addJob,
  } = useJobs();

  const { companies, setParams: setCompaniesParams } = useOrganizations();
  const { skills, setSkillParams } = useSkills();
  const [error, setError] = useState<string | null>(null);

  const handleAddJob = () => {
    form.resetFields();
    setIsModalOpen(true);
  }

  const handleAccept = async () => {
    const values = form.getFieldsValue();
    const companyId =
      companies.find((item) => item?.name === values?.companyName)?.id ||
      jobData?.company.id as number;

    const skills = urlParams.id ? values.requiredSkills.map(({ value }: { value: number, label: string }) => value) : values.requiredSkills;
    const payload: AddEditJobPayload = {
      companyId,
      city: values.city,
      companyOverview: values.companyOverview,
      experienceInYears: values.experience,
      hiringManagerMail: values.hiringManagerMail,
      isFeatured: values.isFeatured || false,
      isRemote: values.isRemote || false,
      jobTitle: values.jobTitle,
      lowerPayRate: values.payRateMin,
      payType: values.payType,
      positionLevel: values.positionLevel,
      requirements: values.jobRequirements,
      responsibilities: values.dutiesResponsibilities,
      skillIds: skills,
      state: values.state,
      supplementPayType: values.supplementalPay,
      upperPayRate: values.payRateMax,
      country: values.country,
      employmentType: values.employmentType,
      categories: values.jobCategories,
      benefits: values.benefits,
      rank: values.rank || 0
    }

    try {
      if (urlParams.id) {
        await updateJob({ id: urlParams.id, payload });
      }
      else {
        await addJob(payload);
      }
      setIsModalOpen(false);
    } catch (error) {
      const err = error as AxiosError<ValidationErrorResponse>;
      if (err.response?.data.error) {
        setError(formatErrors(err.response?.data.error));
        return;
      } 
      setError(err.response?.data.message || 'Something went wrong');
    }
  }

  const getModalTitle = (): string => {
    if (urlParams.id) {
      return 'Edit Job Listing'
    }

    return 'Add Job Listing'
  }

  const handlePageChange = (nextPage: number, pageSize: number) => {
    setParams((prev) => ({
      ...prev,
      page: nextPage,
      limit: pageSize
    }));
  }

  const handleEdit = (item: Job): any => {
    setSelectedJobId(+item.id);
    navigate(item.id.toString(), { relative: 'route' });
  }

  const handleDelete = (item: Job) => {
    deleteJobs({ jobIds: [item.id]});
  }

  const setFormValues = useCallback((data: JobRecordResponse) => {
    form.setFieldsValue({
      companyName: data.company.name,
      companyOverview: data.companyOverview,
      city: data.location?.city,
      state: data.location?.state,
      country: data.location?.country,
      jobTitle: data.jobTitle,
      employmentType: data.employmentType,
      experience: data.experienceInYears,
      positionLevel: data.positionLevel,
      payType: data.payType,
      payRateMin: data.lowerPayRate,
      payRateMax: data.upperPayRate,
      supplementalPay: data.supplementPayType,
      jobCategories: data.categories,
      requiredSkills: data.skills.map((skill) => ({ value: skill.id, label: skill.name})),
      dutiesResponsibilities: data.responsibilities,
      jobRequirements: data.requirements,
      benefits: data.benefits,
      hiringManagerMail: data.hiringManagerMail,
      isRemote: data.isRemote,
      isFeatured: data.isFeatured,
      rank: data.rank
    });
  }, [form]);

  const handleSearch = (text: string) => {
    if (!text) {
      return
    }
    setCompaniesParams({ page: 1, limit: 10, name: text })
  }

  const handleSkillSearch = (text: string) => {
    if (!text) {
      setSkillParams( { page: 1, limit: 20 })
    } else {
      setSkillParams( { page: 1, limit: 20, name: text })
    }
  }

  const handleCompanySelect = (name: string) => {
    const overview = companies.find((item) => item.name === name)?.companyOverview || ''
    if (overview) {
      form.setFieldValue('companyOverview', overview);
    }
  }

  useEffect(() => {
    if(urlParams.id) {
      setSelectedJobId(+urlParams.id);
      setIsModalOpen(true);
    }
  }, [urlParams.id, setSelectedJobId, setIsModalOpen])

  useEffect(() => {
    if(jobData && urlParams.id) {
      setFormValues(jobData)
    }
  }, [form, jobData, setFormValues, urlParams.id])

  useEffect(() => {
    setMappedCompanies(companies.map((company) => ({ label: company.name, value: company.name })))
  }, [companies, setMappedCompanies])

  useEffect(() => {
    form.validateFields().then(() => {
      setIsFormValid(true);
    },
    () => {
      setIsFormValid(false);
    })
  }, [form, watchedValues]);

  return (
    <div className="page-wrapper">
      <div className="page-header">
        <PageTitle title="Job Listings" />
        <ActionButtons
          primaryBtnLabel="Add Job Listing"
          primaryBtnCb={() => handleAddJob()}
          secondaryBtnLabel="Upload CSV"
          secondaryBtnCb={() => setIsCSVModalOpen(true)}
        />
      </div>
      <div className="page-content">
        <Table<Job>
          columns={JobColumns(handleEdit, handleDelete)}
          tableProps={JobsTableProps}
          data={jobs}
          isLoading={isLoading}
          current={params.page}
          total={total}
          pageSize={params.limit}
          onPageChange={handlePageChange}
        />
      </div>
      <Modal
        title={getModalTitle()}
        open={isModalOpen}
        onOk={handleAccept}
        onCancel={() => setIsModalOpen(false)}
        afterClose={() => navigate(Path.Jobs)}
        okButtonProps={{
          disabled: !isFormValid,
        }}
      >
        <div className="modal-content">
          <JobForm
            form={form}
            onSearch={handleSearch}
            companyOptions={mappedCompanies}
            onSkillSearch={handleSkillSearch}
            skills={skills}
            onCompanySelect={handleCompanySelect}
          />
          {error && (
            <Alert
              message={error}
              type="error"
              closable
              onClose={() => setError(null)}
            />
          )}
        </div>
      </Modal>
      <UploadCSVModal  isOpen={isCSVModalOpen} onCancel={() => setIsCSVModalOpen(false)}/>
    </div>
  )
}
