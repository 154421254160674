import {
  Company,
  CompanyResponse,
  Course,
  CourseResponse,
  Discount,
  DiscountResponse,
  User,
  UserResponse
} from 'types/data';
import { DiscountTypeEnum } from 'common/enums';
import { Job, JobResponse } from 'types/job';
import { ValidationError } from 'types/common';

const formatDiscount = (discount: number, type: DiscountTypeEnum): string => {
  if (type === DiscountTypeEnum.Percentage) {
    return `${discount}% off`;
  }

  return `$${discount} off`;
}

export const mapResponseToJobs = (response: JobResponse[]): Job[] => {
  if (!response) {
    return [];
  }
  return response.map(({ id, company, jobTitle, rank, location, createdAt, updatedAt }) => ({
    id,
    company: company.name,
    jobTitle,
    rank,
    createdAt,
    updatedAt,
    location: `${ location.city + ', ' + location.state }`
  }))
}

export const mapResponseToCompanies = (response: CompanyResponse[]): Company[] => {
  if (!response) {
    return [];
  }
  return response.map(({ id, name, logo, createdAt, updatedAt, externalLink, location, companyOverview }) => ({
    id,
    name,
    logo,
    createdAt,
    updatedAt,
    companyOverview,
    location: `${ location?.city + ', ' + location?.state }`,
    website: externalLink,
  }))
}

export const mapResponseToUsers = (response: UserResponse[]): User[] => {
  if (!response) {
    return [];
  }
  return response.map(({ id, firstName, lastName, role, profileImg, companyName, jobTitle, email }) => ({
    id,
    firstName,
    lastName,
    jobTitle,
    companyName,
    email,
    status: role,
    image: profileImg
  }))
}

export const mapResponseToCourses = (response: CourseResponse[]): Course[] => {
  if (!response) {
    return [];
  }
  return response.map(({ id, company, title, createdAt, updatedAt, category, discount, discountType, rank }) => ({
    id,
    category,
    createdAt,
    updatedAt,
    rank,
    organization: company?.name,
    name: title,
    discount: formatDiscount(discount, discountType)
  }))
}

export const mapResponseToDiscounts = (response: DiscountResponse[]): Discount[] => {
  if (!response) {
    return [];
  }
  return response.map(({ id, company, title, discount, discountType, updatedAt, category, createdAt, code, rank }) => ({
    id,
    title,
    category,
    createdAt,
    updatedAt,
    code,
    rank,
    companyName: company?.name,
    discount: formatDiscount(discount, discountType)
  }))
}

export const getDate = (input: string): string => {
  const date = new Date(input);
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return month + '/' + day + '/' + year;
}

export const defaultParams = { page: 1, limit: 10 };

export const formatErrors = (errors: ValidationError[]) => {
  return errors.map((error) => error.message).join(', ');
}