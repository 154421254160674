import { UploadProps } from 'antd';
import { usePictureUpload } from 'hooks/usePictureUpload';

export const useCSVUpload = () => {
  const {
    fileList,
    setFileList,
    uploadLastSelected
  } = usePictureUpload()

  const uploadCSVProps: UploadProps = {
    name: 'file',
    multiple: false,
    onChange() {
      return false;
    }
  };

  return {
    uploadCSVProps,
    fileList,
    setFileList,
    uploadLastSelected,
  }
}
