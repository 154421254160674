import { Button, Form, FormInstance, Input, Modal, Select, Upload, UploadFile } from 'antd';
import { StatesList } from 'shame/states';
import TextArea from 'antd/es/input/TextArea';
import { companyTypeList } from 'shame/companyType';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useAdmins } from 'hooks/useAdmins';
import { useParams } from 'react-router-dom';
import { ReactComponent as Remove } from 'assets/icons/remove.svg';
import { useEffect, useState } from 'react';
import { UserRecordResponse } from 'types/data';
import './Organizations.scss';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadButton } from 'common/UploadButton';
import { formRules } from './consts/formRules';

interface OrganizationFormProps {
  form: FormInstance;
  image?: string | null;
  fileList: UploadFile[],
  previewOpen: boolean,
  previewImage: string,
  previewTitle: string,
  openPreview: (file: UploadFile) => void,
  closePreview: () => void,
  setFileList: (list: UploadFile[]) => void,
  handleChange: (info: UploadChangeParam<UploadFile<any>>) => void,
}

export const OrganizationForm = ({
                                   form,
                                   image,
                                   fileList,
                                   previewOpen,
                                   previewImage,
                                   previewTitle,
                                   openPreview,
                                   closePreview,
                                   setFileList,
                                   handleChange,
                                 }: OrganizationFormProps) => {
  const urlParams = useParams();
  const { admins, addAdmin, deleteAdmins } = useAdmins(urlParams.id);
  const [inputValue, setInputValue] = useState('');

  const onDelete = (user: UserRecordResponse) => {
    deleteAdmins({
      companyId: urlParams.id as string,
      userIds: [user.id]
    })
  }

  const onAdd = () => {
    addAdmin({
      companyId: urlParams.id as string,
      email: inputValue,
    })
    setInputValue('');
  }

  useEffect(() => {
    if (image) {
      setFileList([{
        uid: '-1',
        name: 'Discount image',
        status: 'done',
        url: image
      }])
    } else {
      setFileList([])
    }
  }, [image, setFileList])

  return (
    <Form
      layout="vertical"
      form={form}
      style={{ marginTop: '24px' }}
      validateTrigger="onInput"
    >
      <Form.Item label="Organization Name" name="companyName" rules={formRules.companyName}>
        <Input />
      </Form.Item>
      <Form.Item label="Organization Type" name="companyType">
        <Select
          allowClear
          options={companyTypeList}
        />
      </Form.Item>
      <Form.Item label="Website Link" name="externalLink" rules={formRules.externalLink}>
        <Input />
      </Form.Item>
      <Form.Item label="Address" name="address" rules={formRules.address}>
        <Input />
      </Form.Item>
      <div className="row-wrapper">
       <Form.Item label="City" name="city" rules={formRules.city}>
          <Input placeholder="Company city" />
        </Form.Item>
        <Form.Item label="State" name="state" rules={formRules.state}>
         <Select
           allowClear
           options={StatesList}
         />
        </Form.Item>
        <Form.Item label="Country" name="country" rules={formRules.country}>
         <Select
           allowClear
           options={[{ value: 'us', label: 'United States' }, { value: 'ca', label: 'Canada' }]}
         />
        </Form.Item>
      </div>
      <div className="row-wrapper">
        <Form.Item label="Phone" name="phone" rules={formRules.phone}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={formRules.email}>
          <Input />
        </Form.Item>
      </div>
      {!urlParams.id && (
        <Form.Item label="Password" name="password" rules={formRules.password}>
          <Input.Password
          placeholder="Enter 8 digit password"
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
      )}
      <Form.Item label="Company Overview" name="companyOverview" rules={formRules.companyOverview}>
        <TextArea rows={3} />
      </Form.Item>
      {urlParams.id && (
        <Form.Item label="Assigned Admins" name="assignedAdmins">
          <>
            <div className="input-wrapper">
              <Input
                className="admin-input"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <Button
                className="admin-add-btn"
                onClick={onAdd}>Add admin</Button>
            </div>
            <div className="admin-wrapper">
              {admins && admins.users?.length > 0 && (admins.users.map((user) => (
                <div className="admin-item">
                  <div className="admin-email">
                    <span>{user.email}</span>
                  </div>
                  <Button
                    className="admin-del-btn"
                    type="text"
                    icon={<Remove className="remove" />}
                    onClick={() => onDelete(user)} />
              </div>
              )))}
            </div>
          </>
        </Form.Item>
      )}
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={openPreview}
        onChange={handleChange}
        beforeUpload={() => {
          return false;
        }}
      >
        {fileList.length >= 1 ? null : UploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={closePreview}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Form>
  )
}
