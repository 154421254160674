import { ADMINS_URL } from 'api/urls';
import { client } from 'api/client/client';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export type DeleteAdminInput = {
  companyId: string;
  userIds: number[];
}

const localDeleteAdmins = async ({ companyId, userIds }: DeleteAdminInput): Promise<Response> => {
  const response = await client({
    url: `${ADMINS_URL}/${companyId}/admins`,
    method: 'DELETE',
    data: { userIds },
  });
  return response.data;
};

export const DeleteAdmins =(
  config?: UseMutationOptions<Response, AxiosError, DeleteAdminInput>
) => {
  return useMutation<Response, AxiosError, DeleteAdminInput>(localDeleteAdmins, config);
}
