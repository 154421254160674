import { DISCOUNTS_URL } from 'api/urls';
import { client } from 'api/client/client';
import { AddEditDiscountPayload } from 'types/data';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';


interface AddDiscountResponse extends Response{
  message: string;
  data: {
    id: number;
  }
}

const localAddDiscount = async ({ ...input }: AddEditDiscountPayload): Promise<AddDiscountResponse> => {
  const response = await client({
    url: `${DISCOUNTS_URL}`,
    method: 'POST',
    data: input,
  });
  return response.data;
};

export const AddDiscount =(
  config?: UseMutationOptions<AddDiscountResponse, AxiosError, AddEditDiscountPayload>
) => {
  return useMutation<AddDiscountResponse, AxiosError, AddEditDiscountPayload>(localAddDiscount, config);
}
