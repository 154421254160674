import { ADMINS_URL } from 'api/urls';
import { client } from 'api/client/client';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export type AddAdminInput = {
  email: string;
  companyId: string
}

const localAddAdmin = async ({ email, companyId }: AddAdminInput): Promise<Response> => {
  const response = await client({
    url: `${ADMINS_URL}/${companyId}/admins`,
    method: 'POST',
    data: { email },
  });
  return response.data;
};

export const AddAdmin =(
  config?: UseMutationOptions<Response, AxiosError, AddAdminInput>
) => {
  return useMutation<Response, AxiosError, AddAdminInput>(localAddAdmin, config);
}
