import { CompanyRecordResponse, CompanyResponse } from 'types/data';
import type { AxiosError } from 'axios';

import { useQuery, UseQueryOptions } from 'react-query';
import { client, getQueryKey } from 'api/client/client';
import { COMPANIES_URL } from 'api/urls';

export type CompaniesParams = {
  page?: number;
  limit?: number
  name?: string;
};

type Response = {
  count: number;
  companies: CompanyResponse[];
}

const localGetCompanies = async({ ...params }: CompaniesParams): Promise<Response> => {
  const response = await client({
    url: COMPANIES_URL,
    method: 'GET',
    params,
  });
  return response.data.data;
}

export const GetCompanies = (
  params: CompaniesParams,
  config?: UseQueryOptions<Response, AxiosError>
)  => {
  const queryKey = [getQueryKey('companies'), params.page, params.name];
  return useQuery<Response, AxiosError>(
    queryKey,
    () => localGetCompanies(params),
    config
  );
}

export type CompanyRecordPrams = { id: number | undefined};

const localGetCompany = async({id} : CompanyRecordPrams): Promise<CompanyRecordResponse> => {
  const response = await client({
    url: `${COMPANIES_URL}/${id}`,
    method: 'GET',
  });
  return response.data.data;
}

export const GetCompany = (
  params: CompanyRecordPrams,
) => {
  return useQuery<CompanyRecordResponse, AxiosError>({
    queryKey: ['company', params],
    queryFn: () => localGetCompany(params),
    enabled: !!params.id
  });
}
