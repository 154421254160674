import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AddEditDiscountPayload, Discount } from 'types/data';
import { defaultParams, mapResponseToDiscounts } from 'common/utils';
import { removeToken } from 'hooks/auth/utils';
import { Path } from 'routes';
import { DiscountsParams, GetDiscount, GetDiscounts } from 'api/discounts/getDiscounts';
import { AddDiscount } from 'api/discounts/addDiscount';
import { useQueryClient } from 'react-query';
import { UpdateDiscount, UpdateDiscountPayload } from 'api/discounts/updateDiscount';
import { DeleteDiscounts } from 'api/discounts/deleteDiscount';
import { useFilterContext } from 'core/FilterContext';
import { usePictureUpload } from 'hooks/usePictureUpload';
import { UploadDiscountImage } from 'api/discounts/uploadDiscountImage';

export const useDiscounts = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { discountName } = useFilterContext();
  const [params, setParams] = useState<DiscountsParams>(defaultParams)
  const [total, setTotal] = useState<number>();
  const [selectedDiscountId, setSelectedDiscountId] = useState<number>();
  const [discounts, setDiscounts] = useState<Discount[]>([])
  const { data, isLoading, error, refetch } = GetDiscounts(params);
  const { data: discountData, isLoading: isLoadingDiscount, error: discountError } = GetDiscount({id: selectedDiscountId})

  const {
    fileList,
    previewOpen,
    previewImage,
    previewTitle,
    openPreview,
    closePreview,
    setFileList,
    handleChange,
  } = usePictureUpload()

  const { mutate: uploadImage } = UploadDiscountImage({
      onSuccess: () => {
      },
    }
  );

  const { mutateAsync: localAddDiscount } = AddDiscount({
    onSuccess: (response) => {
      uploadImage({id: response.data.id.toString(), file: fileList[0] });
      invalidateCache();
      setFileList([]);
    },
  });

  const { mutate: deleteDiscounts } = DeleteDiscounts({
    onSuccess: () => {
      invalidateCache();
      refetch();
    },
  });

  const { mutateAsync: localUpdateDiscount } = UpdateDiscount({
    onSuccess: () => {
      invalidateCache();
    },
  });

  const invalidateCache = () => {
    queryClient.invalidateQueries(['discounts']);
    queryClient.invalidateQueries(['discount', { id: null }]);
    navigate(Path.Discounts);
  }

  const addDiscount = async (input: AddEditDiscountPayload) => {
    await localAddDiscount(input);
  }

  const updateDiscount = async (input: UpdateDiscountPayload) => {
    await localUpdateDiscount(input);
    uploadImage({id: input.id, file: fileList[0]});
    setFileList([]);
  }

  useEffect(() => {
    if(data) {
      setDiscounts(mapResponseToDiscounts(data.discounts));
      setTotal(data.count);
    }

    if(error) {
      removeToken();
      navigate(Path.Login);
    }
  }, [data, error, navigate, setDiscounts])

  useEffect(() => {
    if (discountName) {
      return setParams((prev) => ({ ...prev, title: discountName }));
    }
    setParams(defaultParams)
  }, [discountName])


  return {
    discounts,
    isLoading,
    params,
    setParams,
    total,
    discountData,
    discountError,
    isLoadingDiscount,
    setSelectedDiscountId,
    addDiscount,
    updateDiscount,
    deleteDiscounts,
    fileList,
    previewOpen,
    previewImage,
    previewTitle,
    openPreview,
    closePreview,
    setFileList,
    handleChange,
  }
}
