import { AddAdmin } from 'api/admins/addAdmin';
import { DeleteAdmins } from 'api/admins/deleteAdmins';
import { AdminParams, GetAdmins } from 'api/admins/getAdmins';
import { useEffect, useState } from 'react';
import { defaultParams } from 'common/utils';
import { useQueryClient } from 'react-query';

export const useAdmins = (companyId?: string) => {
  const queryClient = useQueryClient();
  const [params, setParams] = useState<AdminParams>({ ...defaultParams, companyId })
  const { data: admins, refetch } = GetAdmins(params);


  const { mutate: addAdmin } = AddAdmin({
    onSuccess: () => {
      invalidateCache();
      refetch();
    },
  });

  const { mutate: deleteAdmins } = DeleteAdmins({
    onSuccess: () => {
      invalidateCache();
      refetch();
    },
  });

  const invalidateCache = () => {
    queryClient.invalidateQueries(['admins']);
  }

  useEffect(() => {
    setParams((prev) => ({...prev, companyId: companyId}))
  }, [companyId])

  return {
    admins,
    addAdmin,
    deleteAdmins
  }
}
