import { client } from 'api/client/client';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { USERS_UPDATE_URL } from 'api/urls';
import { UpdateUser as UpdateUserInterface } from 'types/data';

export type UpdateUserPayload = {
  id: number;
  payload: UpdateUserInterface;
}

const localUpdateUser = async ({ id, payload }: UpdateUserPayload): Promise<Response> => {
  const response = await client({
    url: `${USERS_UPDATE_URL}/${id}`,
    method: 'PATCH',
    data: payload,
  });
  return response.data;
};

export const UpdateUser =(
  config?: UseMutationOptions<Response, AxiosError, UpdateUserPayload>
) => {
  return useMutation<Response, AxiosError, UpdateUserPayload>(localUpdateUser, config);
}
