import { Button } from 'antd';
import { ReactComponent as Upload } from 'assets/icons/upload.svg';
import './ActionButtons.scss';


export interface ActionButtonsProps {
  primaryBtnLabel: string
  primaryBtnCb: () => void
  secondaryBtnLabel: string
  secondaryBtnCb: () => void
  isSecondaryBtnHidden?: boolean
}

export const ActionButtons = ({
                                primaryBtnLabel = 'Add Job Listing',
                                primaryBtnCb = () => undefined,
                                secondaryBtnLabel = 'Upload CSV',
                                secondaryBtnCb = () => undefined,
                                isSecondaryBtnHidden = false
                              }: ActionButtonsProps) => {

  return (
    <div className="action-btn-wrapper">
      {!isSecondaryBtnHidden && <Button onClick={secondaryBtnCb} icon={<Upload />} className="custom-btn secondary">{secondaryBtnLabel}</Button> }
      <Button type="primary" onClick={primaryBtnCb} className="custom-btn primary">{primaryBtnLabel}</Button>
    </div>
  )
}
