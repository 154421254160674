export enum UserRole {
  Professional = 'professional',
  Admin = 'admin',
  Member = 'member'
}

export enum DiscountTypeEnum {
  Percentage = 'PERCENTAGE',
  Dollars = 'DOLLARS'
}

export enum CountryEnum {
  US = 'United States',
  Canada = 'Canada'
}
