export enum PayType {
  HOURLY = 'hourly',
  SALARY = 'salary',
  INTERN = 'intern',
  OTHER = 'other',
}

export enum SupplementPayType {
  COMMISSION = 'commission',
  BONUS = 'bonus',
  TIPS = 'tips',
  OTHER = 'other',
}

export const payTypeList = [
  {value: PayType.HOURLY, label: 'Hourly'},
  {value: PayType.SALARY, label: 'Salary'},
  {value: PayType.INTERN, label: 'Internship'},
  {value: PayType.OTHER, label: 'Other'},
]

export const supplementPayTypeList = [
  {value: SupplementPayType.COMMISSION, label: 'Commission'},
  {value: SupplementPayType.BONUS, label: 'Bonus'},
  {value: SupplementPayType.TIPS, label: 'Tips'},
  {value: SupplementPayType.OTHER, label: 'Other'},
]
