import { RouteObject } from 'react-router';
import { Layout } from 'layout/Layout';
import { Jobs } from 'components/Jobs/Jobs';
import { Login } from 'components/Login/Login';
import { Organizations } from 'components/Organizations/Organizations';
import { Education } from 'components/Education/Education';
import { Users } from 'components/Users/Users';
import { Discounts } from 'components/Discounts/Discounts';
import { NotFound } from 'components/NotFound/NotFound';
import { AppLoader } from 'components/AppLoader/AppLoader';

export enum Path {
  Root = '/',
  Jobs = '/jobs',
  Discounts = '/discounts',
  Education = '/education',
  Organizations = '/organizations',
  Users = '/users',
  Login = '/login',
}

export const Navigation = [
  Path.Jobs,
  Path.Discounts,
  Path.Education,
  Path.Organizations,
  Path.Users
]

export const Routes: RouteObject[] = [
  {
    path: Path.Root,
    element: <AppLoader />
  },
  {
    element: <Layout />,
    children: [
      {
        path: Path.Jobs,
        element: <Jobs />,
      },
      {
        path: `${Path.Jobs}/:id`,
        element: <Jobs />,
      },
      {
        path: Path.Discounts,
        element: <Discounts />,
        children: [
          {
            path: ':id',
            element: <Discounts />,
          },
        ]
      },
      {
        path: Path.Education,
        element: <Education />,
        children: [
          {
            path: ':id',
            element: <Education />,
          },
        ]
      },
      {
        path: Path.Organizations,
        element: <Organizations />,
        children: [
          {
            path: ':id',
            element: <Organizations />,
          },
        ]
      },
      {
        path: Path.Users,
        element: <Users />,
        children: [
          {
            path: ':id',
            element: <Users />,
          },
        ]
      },
    ]
  }, {
    path: Path.Login,
    element: <Login />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]
