import { useDiscounts } from 'hooks/useDiscounts';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { ActionButtons } from 'components/ActionButtons/ActionButtons';
import { Table } from 'components/Table/Table';
import { useNavigate, useParams } from 'react-router-dom';
import { AddEditDiscountPayload, Discount, DiscountRecordResponse } from 'types/data';
import { DiscountTableProps } from 'components/Discounts/consts/tableProps';
import { DiscountColumns } from 'components/Discounts/consts/columns';
import { Path } from 'routes';
import { Alert, Form, Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { DiscountForm } from 'components/Discounts/DiscountForm';
import { UploadCSVModal } from 'components/UploadCSVModal/UploadCSVModal';
import { useOrganizations } from 'hooks/useOrganizations';
import dayjs from 'dayjs';
import { formatErrors } from 'common/utils';
import { AxiosError } from 'axios';
import { ValidationErrorResponse } from 'types/common';

export const Discounts = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mappedCompanies, setMappedCompanies] = useState<Array<{ label: string, value: string }>>([])
  const [isCSVModalOpen, setIsCSVModalOpen] = useState(false);
  const [form] = Form.useForm();
  const watchedValues = Form.useWatch([], form);
  const [isFormValid, setIsFormValid] = useState(false);
  const {
    discounts,
    isLoading,
    params,
    setParams,
    total,
    discountData,
    setSelectedDiscountId,
    addDiscount,
    updateDiscount,
    deleteDiscounts,
    fileList,
    previewOpen,
    previewImage,
    previewTitle,
    openPreview,
    closePreview,
    setFileList,
    handleChange,
  } = useDiscounts();

  const { companies, setParams: setCompaniesParams } = useOrganizations();
  const [error, setError] = useState<string | null>(null);

  const handlePageChange = (nextPage: number, pageSize: number) => {
    setParams((prev) => ({
      ...prev,
      page: nextPage,
      limit: pageSize
    }));
  }

  const handleAddDiscount = () => {
    setSelectedDiscountId(undefined);
    setFileList([]);
    form.resetFields();
    setIsModalOpen(true);
  }

  const handleAccept = async () => {
    const values = form.getFieldsValue();
    console.log(values)
    const companyId =
      companies.find((item) => item?.name === values?.companyName)?.id ||
      discountData?.company.id as number;
    const payload: AddEditDiscountPayload = {
      companyId: companyId,
      discount: values.discount,
      discountType: values.discountType,
      category: values.category,
      code: values.discountCode,
      description: values.description,
      externalLink: values.codeLink,
      title: values.offering,
      expiryDate: values.expiryDate,
      image: discountData?.image || '',
      rank: values.rank || 0,
      isFeatured: values.isFeatured || false
    }

    try {
      if (urlParams.id) {
        await updateDiscount({ id: urlParams.id, payload });
      }
      else {
        await addDiscount(payload);
      }
      setIsModalOpen(false);
    } catch (error) {
      const err = error as AxiosError<ValidationErrorResponse>;
      if (err.response?.data.error) {
        setError(formatErrors(err.response?.data.error));
        return;
      } 
      setError(err.response?.data.message || 'Something went wrong');
    }
  }

  const handleEdit = (item: Discount) => {
    setSelectedDiscountId(+item.id)
    navigate(item.id.toString(), { relative: 'route' });
  }

  const handleDelete = (item: Discount) => {
    deleteDiscounts({ discountIds: [item.id]});
  }

  const getModalTitle = (): string => {
    if (urlParams.id) {
      return 'Edit Discount'
    }

    return 'Add Discount'
  }

  const setFormValues = useCallback((data: DiscountRecordResponse) => {
    form.setFieldsValue({
      companyName: data.company?.name,
      category: data.category,
      offering: data.title,
      discount: data.discount,
      discountType: data.discountType,
      discountCode: data.code,
      codeLink: data.externalLink,
      description: data.description,
      expiryDate: dayjs(data.expiryDate),
      isFeatured: data.isFeatured,
      rank: data.rank
    });
  }, [form]);

  const handleSearch = (text: string) => {
    if (!text) {
      return
    }
    setCompaniesParams({ page: 1, limit: 10, name: text })
  }

  useEffect(() => {
    if(urlParams.id) {
      setSelectedDiscountId(+urlParams.id);
      setIsModalOpen(true);
    }
  }, [urlParams.id, setSelectedDiscountId, setIsModalOpen])

  useEffect(() => {
    if(discountData && urlParams.id) {
      setFormValues(discountData)
    }
  }, [form, discountData, setFormValues, urlParams.id])

  useEffect(() => {
    setMappedCompanies(companies.map((company) => ({ label: company.name, value: company.name })))
  }, [companies, setMappedCompanies])

  useEffect(() => {
    form.validateFields().then(() => {
      setIsFormValid(true);
    },
    () => {
      setIsFormValid(false);
    })
  }, [form, watchedValues]);

  return (
    <div className="page-wrapper">
      <div className="page-header">
        <PageTitle title="Discounts" />
        <ActionButtons
          primaryBtnLabel="Add Discount"
          primaryBtnCb={handleAddDiscount}
          secondaryBtnLabel="Upload CSV"
          secondaryBtnCb={() => setIsCSVModalOpen(true)}
          isSecondaryBtnHidden={true}
        />
      </div>
      <div className="page-content">
        <Table<Discount>
          columns={DiscountColumns(handleEdit, handleDelete)}
          tableProps={DiscountTableProps}
          data={discounts}
          isLoading={isLoading}
          current={params.page}
          total={total}
          pageSize={params.limit}
          onPageChange={handlePageChange} />
      </div>
      <Modal
        title={getModalTitle()}
        open={isModalOpen}
        onOk={handleAccept}
        onCancel={() => setIsModalOpen(false)}
        afterClose={() => navigate(Path.Discounts)}
        okButtonProps={{
          disabled: !isFormValid,
        }}
      >
        <div className="modal-content">
          <DiscountForm
            form={form}
            options={mappedCompanies}
            onSearch={handleSearch}
            image={discountData?.image}
            fileList={fileList}
            previewOpen={previewOpen}
            previewImage={previewImage}
            previewTitle={previewTitle}
            openPreview={openPreview}
            closePreview={closePreview}
            setFileList={setFileList}
            handleChange={handleChange}
          />
          {error && (
            <Alert
              message={error}
              type="error"
              closable
              onClose={() => setError(null)}
            />
          )}
        </div>
      </Modal>
      <UploadCSVModal  isOpen={isCSVModalOpen} onCancel={() => setIsCSVModalOpen(false)}/>
    </div>
  )
}
