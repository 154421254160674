import { useNavigate, useParams } from 'react-router-dom';
import { ActionButtons } from 'components/ActionButtons/ActionButtons';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { Table } from 'components/Table/Table';
import { AddEditCoursePayload, Course, CourseRecordResponse } from 'types/data';
import { useEducation } from 'hooks/useEducation';
import { CourseColumns } from 'components/Education/consts/columns';
import { CourseTableProps } from 'components/Education/consts/tableProps';
import { Path } from 'routes';
import { Alert, Form, Modal } from 'antd';
import { EducationForm } from 'components/Education/EducationForm';
import { useCallback, useEffect, useState } from 'react';
import { UploadCSVModal } from 'components/UploadCSVModal/UploadCSVModal';
import { useOrganizations } from 'hooks/useOrganizations';
import { AxiosError } from 'axios';
import { formatErrors } from 'common/utils';
import { ValidationErrorResponse } from 'types/common';


export const Education = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mappedCompanies, setMappedCompanies] = useState<Array<{ label: string, value: string }>>([])
  const [isCSVModalOpen, setIsCSVModalOpen] = useState(false);
  const [form] = Form.useForm();
  const watchedValues = Form.useWatch([], form);
  const [isFormValid, setIsFormValid] = useState(false);
  const {
    courses,
    isLoading,
    params,
    setParams,
    total,
    courseData,
    setSelectedCourseId,
    updateCourse,
    addCourse,
    deleteCourses,
    fileList,
    previewOpen,
    previewImage,
    previewTitle,
    openPreview,
    closePreview,
    setFileList,
    handleChange,
  } = useEducation();

  const { companies, setParams: SetCompaniesParams } = useOrganizations();
  const [error, setError] = useState<string | null>(null);

  const handleAddCourse = () => {
    setSelectedCourseId(undefined);
    setFileList([]);
    form.resetFields();
    setIsModalOpen(true);
  }

  const handleAccept = async () => {
    const values = form.getFieldsValue();
    const companyId =
      companies.find((item) => item?.name === values?.companyName)?.id ||
      courseData?.company?.id as number;
    const payload: AddEditCoursePayload = {
      companyId,
      banners: courseData?.banners || [],
      category: values.category,
      code: values.code,
      description: values.description,
      details: values.details,
      discount: values.discount,
      discountType: values.discountType,
      externalLink: values.externalLink,
      rank: values.rank || 0,
      isFeatured: values.isFeatured || false,
      title: values.title
    }

    try {
      if (urlParams.id) {
        await updateCourse({ id: urlParams.id, payload });
      }
      else {
        await addCourse(payload);
      }
      setIsModalOpen(false);
    } catch (error) {
      const err = error as AxiosError<ValidationErrorResponse>;
      if (err.response?.data.error) {
        setError(formatErrors(err.response?.data.error));
        return;
      } 
      setError(err.response?.data.message || 'Something went wrong');
    }
  }

  const getModalTitle = (): string => {
    if (urlParams.id) {
      return 'Edit Education Listing'
    }

    return 'Add Education Listing'
  }

  const handlePageChange = (nextPage: number, pageSize: number) => {
    setParams((prev) => ({
      ...prev,
      page: nextPage,
      limit: pageSize
    }));
  }

  const handleEdit = (item: Course) => {
    setSelectedCourseId(+item.id);
    navigate(item.id.toString(), { relative: 'route' });
  }

  const handleDelete = (item: Course) => {
    deleteCourses({ courseIds: [ item.id ]});
  }

  const setFormValues = useCallback((data: CourseRecordResponse) => {
    form.setFieldsValue({
      companyName: data.company.name,
      title: data.title,
      category: data.category,
      discount: data.discount,
      discountType: data.discountType,
      code: data.code,
      externalLink: data.externalLink,
      description: data.description,
      details: data.details,
      isFeatured: data.isFeatured || false,
      rank: data.rank || 0,
    });
  },[form]);

  const handleSearch = (text: string) => {
    if (!text) {
      return
    }
    SetCompaniesParams({ page: 1, limit: 10, name: text })
  }

  useEffect(() => {
    if(urlParams.id) {
      setSelectedCourseId(+urlParams.id);
      setIsModalOpen(true);
    }
  }, [urlParams.id, setIsModalOpen, setSelectedCourseId])

  useEffect(() => {
    if(courseData && urlParams.id) {
      setFormValues(courseData)
    }
  }, [form, courseData, setFormValues, urlParams.id])

  useEffect(() => {
    setMappedCompanies(companies.map((company) => ({ label: company.name, value: company.name })))
  }, [companies, setMappedCompanies])


  useEffect(() => {
    form.validateFields().then(() => {
      setIsFormValid(true);
    },
    () => {
      setIsFormValid(false);
    })
  }, [form, watchedValues]);

  return (
    <div className="page-wrapper">
      <div className="page-header">
        <PageTitle title="Education" />
        <ActionButtons
          primaryBtnLabel="Add Education Listing"
          primaryBtnCb={() => handleAddCourse()}
          secondaryBtnLabel="Upload CSV"
          secondaryBtnCb={() => setIsCSVModalOpen(true)}
          isSecondaryBtnHidden={true}
        />
      </div>
      <div className="page-content">
        <Table<Course>
          columns={CourseColumns(handleEdit, handleDelete)}
          tableProps={CourseTableProps}
          data={courses}
          isLoading={isLoading}
          current={params.page}
          total={total}
          pageSize={params.limit}
          onPageChange={handlePageChange} />
      </div>
      <Modal
        title={getModalTitle()}
        open={isModalOpen}
        onOk={handleAccept}
        onCancel={() => setIsModalOpen(false)}
        afterClose={() => navigate(Path.Education)}
        okButtonProps={{
          disabled: !isFormValid,
        }}
      >
        <div className="modal-content">
          <EducationForm
            form={form}
            options={mappedCompanies}
            onSearch={handleSearch}
            images={courseData?.banners}
            fileList={fileList}
            previewOpen={previewOpen}
            previewImage={previewImage}
            previewTitle={previewTitle}
            openPreview={openPreview}
            closePreview={closePreview}
            setFileList={setFileList}
            handleChange={handleChange}
          />
          {error && (
            <Alert
              message={error}
              type="error"
              closable
              onClose={() => setError(null)}
            />
          )}
        </div>
      </Modal>
      <UploadCSVModal  isOpen={isCSVModalOpen} onCancel={() => setIsCSVModalOpen(false)}/>
    </div>
  )
}
