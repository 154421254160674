import { client } from 'api/client/client';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { AddEditJobPayload } from 'types/job';
import { JOBS_URL } from 'api/urls';

const localAddJob = async ({ ...input }: AddEditJobPayload): Promise<Response> => {
  const response = await client({
    url: `${JOBS_URL}`,
    method: 'POST',
    data: input,
  });
  return response.data;
};

export const AddJob =(
  config?: UseMutationOptions<Response, AxiosError, AddEditJobPayload>
) => {
  return useMutation<Response, AxiosError, AddEditJobPayload>(localAddJob, config);
}
