import { Rule } from "antd/es/form";

export const formRules: Record<string, Rule[]> = {
    email: [
        {
          type: 'email',
          message: 'Please enter a valid email'
        }
      ],
        password: [
        {
            min: 8,
            message: 'Password must be at least 8 characters'
        },
    ],
};
