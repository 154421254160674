import { useEffect, useState } from 'react';
import { GetJob, GetJobs, JobParams } from 'api/jobs/getJobs';
import { defaultParams, mapResponseToJobs } from 'common/utils';
import { Path } from 'routes';
import { useNavigate } from 'react-router-dom';
import { removeToken } from 'hooks/auth/utils';
import { useFilterContext } from 'core/FilterContext';
import { useQueryClient } from 'react-query';
import { DeleteJobs } from 'api/jobs/deleteJobs';
import { Job } from 'types/job';
import { UpdateJob } from 'api/jobs/updateJob';
import { AddJob } from 'api/jobs/addJob';

export const useJobs = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { jobTitle } = useFilterContext();
  const [params, setParams] = useState<JobParams>(defaultParams)
  const [total, setTotal] = useState<number>();
  const [selectedJobId, setSelectedJobId] = useState<number>();
  const [jobs, setJobs] = useState<Job[]>([]);
  const { data, isLoading, error, refetch } = GetJobs(params);
  const { data: jobData, isLoading: isLoadingJob, error: jobError } = GetJob({id: selectedJobId})

  const { mutate: deleteJobs } = DeleteJobs({
    onSuccess: () => {
      invalidateCache();
      refetch();
    },
  });

  const { mutateAsync: updateJob } = UpdateJob({
    onSuccess: () => {
      invalidateCache();
      refetch();
    },
  });

  const { mutateAsync: addJob } = AddJob({
    onSuccess: () => {
      invalidateCache();
      refetch();
    },
  });

  const invalidateCache = () => {
    queryClient.invalidateQueries(['jobs']);
    queryClient.invalidateQueries(['job', { id: null }]);
    navigate(Path.Jobs);
  }

  useEffect(() => {
    if (data) {
      setJobs(mapResponseToJobs(data.jobs));
      setTotal(data.count);
    }

    if (error) {
      removeToken();
      navigate(Path.Login);
    }
  }, [data, error, navigate, setJobs])

  useEffect(() => {
    if (jobTitle) {
      return setParams((prev) => ({ ...prev, jobTitle }));
    }
    setParams(defaultParams);
  }, [jobTitle])

  return {
    isLoading,
    jobs,
    params,
    setParams,
    total,
    jobData,
    isLoadingJob,
    jobError,
    setSelectedJobId,
    deleteJobs,
    updateJob,
    addJob
  }
}
