import { PageTitle } from 'components/PageTitle/PageTitle';
import { ActionButtons } from 'components/ActionButtons/ActionButtons';
import { Table } from 'components/Table/Table';
import { AddEditCompanyPayload, Company, CompanyRecordResponse } from 'types/data';
import { useNavigate, useParams } from 'react-router-dom';
import { useOrganizations } from 'hooks/useOrganizations';
import { OrganizationTableProps } from 'components/Organizations/consts/tableProps';
import { OrganizationColumns } from 'components/Organizations/consts/columns';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'antd';
import { Path } from 'routes';
import { OrganizationForm } from 'components/Organizations/OrganizationForm';
import { formatErrors } from 'common/utils';
import { ValidationErrorResponse } from 'types/common';
import { AxiosError } from 'axios';

export const Organizations = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const watchedValues = Form.useWatch([], form);
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const {
    companies,
    isLoading,
    params,
    setParams,
    total,
    companyData,
    setSelectedCompanyId,
    addCompany,
    updateCompany,
    deleteCompanies,
    fileList,
    previewOpen,
    previewImage,
    previewTitle,
    openPreview,
    closePreview,
    setFileList,
    handleChange,
  } = useOrganizations();

  const handleAddOrganization = () => {
    setSelectedCompanyId(undefined);
    setFileList([]);
    form.resetFields();
    setIsModalOpen(true);
  }

  const handleAccept = async () => {
    const values = form.getFieldsValue();
    const payload: AddEditCompanyPayload = {
      name: values.companyName,
      logo: companyData?.logo || '',
      companyOverview: values.companyOverview,
      email: values.email,
      password: values.password,
      phone: values.phone,
      externalLink: values.externalLink,
      companyType: values.companyType,
      address: values.address,
      city: values.city,
      state: values.state,
      country: values.country
    }

    try {
      if (urlParams.id) {
        await updateCompany({ id: urlParams.id, payload });
      }
      else {
        await addCompany(payload);
      }
      setIsModalOpen(false);
    } catch (error) {
      const err = error as AxiosError<ValidationErrorResponse>;
      if (err.response?.data.error) {
        setError(formatErrors(err.response?.data.error));
        return;
      } 
      setError(err.response?.data.message || 'Something went wrong');
    }
  }

  const getModalTitle = (): string => {
    if (urlParams.id) {
      return 'Edit Organization'
    }

    return 'Add Organization'
  }

  const handlePageChange = (nextPage: number, pageSize: number) => {
    setParams((prev) => ({
      ...prev,
      page: nextPage,
      limit: pageSize
    }))
  }

  const handleEdit = (item: Company): any => {
    setSelectedCompanyId(+item.id);
    navigate(item.id.toString(), { relative: 'route' });
  }

  const handleDelete = (item: Company) => {
    deleteCompanies({ companyIds: [item.id]});
  }

  const setFormValues = useCallback((data: CompanyRecordResponse) => {
    form.setFieldsValue({
      companyName: data.name,
      companyType: data.companyType,
      externalLink: data.externalLink,
      address: data.location?.address,
      city: data.location?.city,
      state: data.location?.state,
      country: data.location?.country,
      phone: data.phone,
      email:data.email,
      companyOverview: data.companyOverview,
    });
  }, [form]);

  // Component did mount
  useEffect(() => {
    if(urlParams.id) {
      setSelectedCompanyId(+urlParams.id);
      setIsModalOpen(true);
    }
  }, [urlParams.id, setSelectedCompanyId, setIsModalOpen])

  useEffect(() => {
    if(companyData && urlParams.id) {
      setFormValues(companyData)
    }
  }, [form, companyData, setFormValues, urlParams.id])

  useEffect(() => {
    form.validateFields().then(() => {
      setIsFormValid(true);
    },
    () => {
      setIsFormValid(false);
    })
  }, [form, watchedValues]);

  return (
    <div className="page-wrapper">
      <div className="page-header">
        <PageTitle title="Organizations" />
        <ActionButtons
          primaryBtnLabel="Add Organization"
          primaryBtnCb={() => handleAddOrganization()}
          secondaryBtnLabel="Upload CSV"
          secondaryBtnCb={() => undefined}
          isSecondaryBtnHidden
        />
      </div>
      <div className="page-content">
        <Table<Company>
          columns={OrganizationColumns(handleEdit, handleDelete)}
          tableProps={OrganizationTableProps}
          data={companies}
          isLoading={isLoading}
          current={params.page}
          total={total}
          pageSize={params.limit}
          onPageChange={handlePageChange} />
      </div>
      <Modal
        title={getModalTitle()}
        open={isModalOpen}
        onOk={handleAccept}
        onCancel={() => setIsModalOpen(false)}
        afterClose={() => navigate(Path.Organizations)}
        okButtonProps={{
          disabled: !isFormValid,
        }}
      >
        <div className="modal-content">
          <OrganizationForm
            form={form}
            image={companyData?.logo}
            fileList={fileList}
            previewOpen={previewOpen}
            previewImage={previewImage}
            previewTitle={previewTitle}
            openPreview={openPreview}
            closePreview={closePreview}
            setFileList={setFileList}
            handleChange={handleChange}
          />
          {error && (
            <Alert
              message={error}
              type="error"
              closable
              onClose={() => setError(null)}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}
