import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Routes } from 'routes';
import { ConfigProvider } from 'antd';
import { theme } from 'theme';
import 'index.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import { FilterProvider } from 'core/FilterContext';

const router = createBrowserRouter(Routes);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({});

root.render(
  <FilterProvider>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={theme}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </QueryClientProvider>
  </FilterProvider>
);
