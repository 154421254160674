import { DiscountRecordResponse, DiscountResponse } from 'types/data';
import type { AxiosError } from 'axios';

import { useQuery, UseQueryOptions } from 'react-query';
import { client, getQueryKey } from 'api/client/client';
import { DISCOUNTS_URL } from 'api/urls';

export type DiscountsParams = {
  page?: number;
  limit?: number
  title?: string;
};

type Response = {
  count: number;
  discounts: DiscountResponse[];
}

const localGetDiscounts= async({ ...params }: DiscountsParams): Promise<Response> => {
  const response = await client({
    url: DISCOUNTS_URL,
    method: 'GET',
    params,
  });
  return response.data.data;
}

export const GetDiscounts = (
  params: DiscountsParams,
  config?: UseQueryOptions<Response, AxiosError>
)  => {
  const queryKey = [getQueryKey('discounts'), params.page, params.title];
  return useQuery<Response, AxiosError>(
    queryKey,
    () => localGetDiscounts(params),
    config
  );
}

export type DiscountRecordPrams = { id: number | undefined};

const localGetDiscount = async({id} : DiscountRecordPrams): Promise<DiscountRecordResponse> => {
  const response = await client({
    url: `${DISCOUNTS_URL}/${id}`,
    method: 'GET',
  });
  return response.data.data;
}

export const GetDiscount = (
  params: DiscountRecordPrams,
) => {
  return useQuery<DiscountRecordResponse, AxiosError>({
    queryKey: ['discount', params],
    queryFn: () => localGetDiscount(params),
    enabled: !!params.id
  });
}
