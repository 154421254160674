import { Rule } from "antd/es/form";

export const formRules: Record<string, Rule[]> = {
    companyName: [
        {
            required: true,
            message: "Company name is required",
            min: 1,
            max: 200,
        }
    ],
    companyOverview: [
        {
            min: 1,
            max: 5000,
            required: false,
            message: "Company overview must be between 1 and 5000 characters",
        }
    ],
    city: [
        {
            required: true,
            message: "City is required",
            min: 1,
            max: 200,
        }
    ],
    state: [
        {
            required: true,
            message: "State is required",
            min: 1,
            max: 200,
        }
    ],
    country: [
        {
            required: true,
            message: "Country is required",
            min: 1,
            max: 200,
        }
    ],
    externalLink: [
        {
            min: 1,
            max: 200,
            required: false,
            type: "url",
            message: "External link must be a valid URL",
        }
    ],
    phone: [
        {
            min: 1,
            max: 200,
            required: true,
            message: "Phone number is required",
        },
    ],
    email: [
        {
            min: 1,
            max: 200,
            type: "email",
            message: "Email must be a valid email",
        },
        {
            required: true,
            message: "Email is required",
        }
    ],
    address: [
        {
            min: 1,
            max: 200,
        }
    ],
    password: [
        {
            min: 1,
            max: 200,
            message: "Password must be between 1 and 200 characters",
        },
        {
            required: true,
            message: "Password is required",
        }
    ],
}