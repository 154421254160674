import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Path } from 'routes';

export const NotFound = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(Path.Root);
  }

  return (
    <Modal
      title="Page not found"
      open={true}
      onOk={handleRedirect}
      onCancel={handleRedirect}
      afterClose={handleRedirect}
    >
        <p> The page you are looking for does not exist</p>
      </Modal>
  )
}
