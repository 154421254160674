import { JOBS_URL } from 'api/urls';
import { client } from 'api/client/client';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export type DeleteJobsInput = {
  jobIds: Array<number>
}

const localDeleteJobs = async ({ ...input }: DeleteJobsInput): Promise<Response> => {
  const response = await client({
    url: `${JOBS_URL}`,
    method: 'DELETE',
    data: input,
  });
  return response.data;
};

export const DeleteJobs =(
  config?: UseMutationOptions<Response, AxiosError, DeleteJobsInput>
) => {
  return useMutation<Response, AxiosError, DeleteJobsInput>(localDeleteJobs, config);
}
