import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AddEditCoursePayload, Course } from 'types/data';
import { defaultParams, mapResponseToCourses } from 'common/utils';
import { removeToken } from 'hooks/auth/utils';
import { Path } from 'routes';
import { CoursesParams, GetCourse, GetCourses } from 'api/courses/getCourses';
import { useQueryClient } from 'react-query';
import { AddCourse } from 'api/courses/addCourse';
import { UpdateCourse, UpdateCoursePayload } from 'api/courses/udpateCourse';
import { DeleteCourses } from 'api/courses/deleteCourse';
import { useFilterContext } from 'core/FilterContext';
import { usePictureUpload } from 'hooks/usePictureUpload';
import { UploadCourseImage } from 'api/courses/uploadCourseImage';

export const useEducation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { courseName } = useFilterContext();
  const [params, setParams] = useState<CoursesParams>(defaultParams)
  const [total, setTotal] = useState<number>();
  const [selectedCourseId, setSelectedCourseId] = useState<number>()
  const [courses, setCourses] = useState<Course[]>([])
  const { data, isLoading, error, refetch } = GetCourses(params);
  const { data: courseData, isLoading: isLoadingCourse, error: courseError } = GetCourse({ id: selectedCourseId });

  const {
    fileList,
    previewOpen,
    previewImage,
    previewTitle,
    openPreview,
    closePreview,
    setFileList,
    handleChange,
  } = usePictureUpload()

  const { mutate: uploadImage } = UploadCourseImage({
      onSuccess: () => {
      },
    }
  );

  const { mutateAsync: localAddCourse } = AddCourse({
    onSuccess: (response) => {
      uploadImage({id: response.data.id.toString(), file: fileList[0] });
      invalidateCache();
      setFileList([]);
    },
  });

  const { mutate: deleteCourses } = DeleteCourses({
    onSuccess: () => {
      invalidateCache();
      refetch();
    },
  });

  const { mutateAsync: localUpdateCourse } = UpdateCourse({
    onSuccess: () => {
      invalidateCache();
      },
  });

  const invalidateCache = () => {
    queryClient.invalidateQueries(['courses']);
    queryClient.invalidateQueries(['course', { id: null }]);
    navigate(Path.Education);
  }

  const addCourse = async (input: AddEditCoursePayload) => {
    await localAddCourse(input);
  }

  const updateCourse = async (input: UpdateCoursePayload) => {
    await localUpdateCourse(input);
    uploadImage({id: input.id, file: fileList[0]});
    setFileList([]);
  }

  useEffect(() => {
    if(data) {
      setCourses(mapResponseToCourses(data.courses));
      setTotal(data.count);
    }

    if(error) {
      removeToken();
      navigate(Path.Login);
    }
  }, [data, error, navigate, setCourses])

  useEffect(() => {
    if (courseName) {
      return setParams((prev) => ({ ...prev, title: courseName }));
    }
    setParams(defaultParams);
  }, [courseName])

  return {
    isLoading,
    courses,
    params,
    setParams,
    total,
    courseData,
    isLoadingCourse,
    courseError,
    setSelectedCourseId,
    addCourse,
    updateCourse,
    deleteCourses,
    fileList,
    previewOpen,
    previewImage,
    previewTitle,
    openPreview,
    closePreview,
    setFileList,
    handleChange,
  }
}
