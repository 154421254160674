import type { AxiosError } from 'axios';

import { useQuery, UseQueryOptions } from 'react-query';
import { client, getQueryKey } from 'api/client/client';
import { USERS_URL } from 'api/urls';
import { UserRecordResponse, UserResponse } from 'types/data';

export type UsersParams = {
  page?: number;
  limit?: number;
  name?: string;
};

type Response = {
  count: number;
  users: UserResponse[];
}

const localGetUsers= async({ ...params }: UsersParams): Promise<Response> => {
  const response = await client({
    url: USERS_URL,
    method: 'GET',
    params,
  });
  return response.data.data;
}

export const GetUsers = (
  params: UsersParams,
  config?: UseQueryOptions<Response, AxiosError>
)  => {
  const queryKey = [getQueryKey('users'), params.page, params.name];
  return useQuery<Response, AxiosError>(
    queryKey,
    () => localGetUsers(params),
    config
  );
}

export type UserRecordPrams = { id: number | undefined};


const localGetUser = async({ id } : UserRecordPrams): Promise<UserRecordResponse> => {
  const response = await client({
    url: `${USERS_URL}/${id}`,
    method: 'GET',
  });
  return response.data.data;
}

export const GetUser = (
  params: UserRecordPrams,
) => {
  return useQuery<UserRecordResponse, AxiosError>({
    queryKey: ['job', params],
    queryFn: () => localGetUser(params),
    enabled: !!params.id
  });
}
