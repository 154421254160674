import {
  AutoComplete,
  Checkbox,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload,
  UploadFile
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { CourseCategoryList } from 'shame/courseCategory';
import { DiscountTypeList } from 'shame/discount';
import { UploadButton } from 'common/UploadButton';
import { useEffect } from 'react';
import { UploadChangeParam } from 'antd/es/upload';
import { formRules } from './consts/formRules';

interface EducationFormProps {
  form: FormInstance;
  options: Array<{ label: string, value: string }>;
  onSearch: (option: string) => void;
  images?: string[];
  fileList: UploadFile[],
  previewOpen: boolean,
  previewImage: string,
  previewTitle: string,
  openPreview: (file: UploadFile) => void,
  closePreview: () => void,
  setFileList: (list: UploadFile[]) => void,
  handleChange: (info: UploadChangeParam<UploadFile<any>>) => void,
}

export const EducationForm = ({
  form,
  options,
  onSearch,
  images,
  fileList,
  previewOpen,
  previewImage,
  previewTitle,
  openPreview,
  closePreview,
  setFileList,
  handleChange,
}: EducationFormProps) => {

  useEffect(() => {
    if (images && images.length > 0) {
      const mappedImages: UploadFile[] = images.map((url, id) => ({
        uid: id.toString(),
        name: 'Education image',
        status: 'done',
        url
      })).filter(Boolean) as UploadFile[];
      setFileList(mappedImages)
    } else {
      setFileList([])
    }
  }, [images, setFileList])

  return (
    <Form
      layout="vertical"
      form={form}
      style={{ marginTop: '24px' }}
    >
      <Form.Item label="Parent Company" name="companyName" required>
        <AutoComplete
          options={options}
          style={{ width: 200 }}
          onSearch={(option) => onSearch(option)}
        />
      </Form.Item>
      <Form.Item label="Course Title" name="title" rules={formRules.title}>
        <Input />
      </Form.Item>
      <Form.Item label="Category" name="category" rules={formRules.category}>
        <Select
          allowClear
          options={CourseCategoryList}
        />
      </Form.Item>
      <div className="row-wrapper">
        <Form.Item label="Discount" name="discount" rules={formRules.discount}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Discount Type" name="discountType" rules={formRules.discountType}>
          <Select
            allowClear
            options={DiscountTypeList}
          />
        </Form.Item>
        <Form.Item label="Rank" name="rank" rules={formRules.rank}>
          <InputNumber />
        </Form.Item>
      </div>
      <div className="row-wrapper">
        <Form.Item label="Course Code" name="code" rules={formRules.code}>
          <Input />
        </Form.Item>
        <Form.Item label="Course Link" name="externalLink" rules={formRules.externalLink}>
          <Input />
        </Form.Item>
      </div>
      <Form.Item label="Course Description" name="description" rules={formRules.description}>
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item label="Course Details" name="details" rules={formRules.details}>
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item name="isFeatured" valuePropName="checked">
        <Checkbox>Show education listing in "Featured"?</Checkbox>
      </Form.Item>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={openPreview}
        onChange={handleChange}
        beforeUpload={() => {
          return false;
        }}
      >
        {fileList.length > 0 ? null : UploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={closePreview}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Form>
  )
}
