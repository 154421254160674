import { AddEditCompanyPayload } from 'types/data';
import { client } from 'api/client/client';
import { COMPANIES_URL } from 'api/urls';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

interface AddCompanyResponse extends Response{
  message: string;
  data: {
    id: number;
  }
}

const localAddCompany = async ({ ...input }: AddEditCompanyPayload): Promise<AddCompanyResponse> => {
  const response = await client({
    url: `${COMPANIES_URL}`,
    method: 'POST',
    data: input,
  });
  return response.data;
};

export const AddCompany =(
  config?: UseMutationOptions<AddCompanyResponse, AxiosError, AddEditCompanyPayload>
) => {
  return useMutation<AddCompanyResponse, AxiosError, AddEditCompanyPayload>(localAddCompany, config);
}
