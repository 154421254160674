import { ConfigProvider, Table as AntDTable, TableProps as AntdTableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Pagination } from 'components/Pagination/Pagination';
import './Table.scss';

export interface TableProps<T> {
  columns: ColumnsType<T>
  tableProps: AntdTableProps<T>
  data: Array<T>
  isLoading: boolean
  current?: number;
  total?: number;
  pageSize?: number;
  onPageChange?: (...input: any) => void;
}

const defaultValues = {
  current: 1,
  total: 10,
  pageSize: 5
}

export const Table = <T extends object>({
    columns,
    tableProps,
    data,
    isLoading,
    current = defaultValues.current,
    total = defaultValues.total,
    pageSize = defaultValues.pageSize,
    onPageChange = () => undefined
  }: TableProps<T>) =>  {
  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: '#18A000',
        colorPrimaryBg: '#EAFFDC'
      }
    }}>
      <AntDTable<T>
        {...tableProps}
        columns={columns}
        dataSource={data ?? []}
        loading={isLoading}
        rowKey="id"
      />
      <Pagination current={current} total={total} pageSize={pageSize} onChange={onPageChange} />
    </ConfigProvider>
  )
}
