import { AdminLoginResponse, LoginInput } from 'types/data';
import { client } from 'api/client/client';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { getToken, removeToken, setToken } from 'hooks/auth/utils';
import { useState } from 'react';


export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => Boolean(getToken()))

  const signIn = (token: string) => {
    setToken(token);
  }

  const signOut = () => {
    setIsLoggedIn(false);
    removeToken();
  }


  const adminLogin = async (input: LoginInput): Promise<AdminLoginResponse> => {
    const { email, password } = input;
    const response = await client({
      url: 'v1/auth/admins/login',
      method: 'POST',
      data: {
        email: email.toLowerCase().trim(),
        password: password.trim(),
      },
    });
    return response.data;
  };

  function useAdminLogin(
    config?: UseMutationOptions<AdminLoginResponse, AxiosError, LoginInput>
  ) {
    return useMutation<AdminLoginResponse, AxiosError, LoginInput>(adminLogin, config);
  }

  return {
    useAdminLogin,
    signIn,
    signOut,
    isLoggedIn
  }
}
