import { ColumnsType } from 'antd/es/table';
import { User } from 'types/data';
import { Button } from 'antd';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Remove } from 'assets/icons/remove.svg';
import Logo from 'assets/logo.png';

export const UserColumns= (
  editCallback: (item: User) => void,
  deleteCallback: (item: User) => void,
  ): ColumnsType<User> => ([
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Image',
    dataIndex: 'image',
    render: (image: string) => {
      if (image) {
        return <img className="table-img" src={image} alt="USER_IMG"/>
      }
      return <img className="table-img" src={Logo} alt="FIBR_LOGO"/>
    }
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',

  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Job Title',
    dataIndex: 'jobTitle',
  },
  {
    title: 'Company',
    dataIndex: 'companyName',
  },
  {
    title: 'Actions',
    key: 'action',
    render: (item) => (
      <div className="actions-wrapper">
        <Button type="text" icon={<Edit className="edit"/>} onClick={() => editCallback(item)}/>
        <Button type="text" icon={<Remove className="remove"/>} onClick={() => deleteCallback(item)}/>
      </div>
    ),
    width: '80px',
    fixed: 'right'
  }
]);
