import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useLogin } from 'hooks/auth/useLogin';
import Logo from 'assets/logo.png';
import './Login.scss'
import { LoginInput } from 'types/data';

export const Login = () => {
  const [form] = Form.useForm();
  const { hasError, login } = useLogin();

  const onSubmit = () => {
    const input = form.getFieldsValue() as LoginInput
    login(input)
  }

  const handleKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      onSubmit();
    }
  }

  return (
    <div className="login-container">
      <div className="content login">
        <div className="logo-wrapper">
          <img src={Logo} alt="FIBR LOGO"/>
        </div>
        <div className="login-box-wrapper">
          <span>Login</span>
          <Form
            layout="vertical"
            form={form}
            style={{ width: 406 }}
          >
            <Form.Item label="Login" name="email" required>
              <Input
                className="custom-input"
                placeholder="Enter your email"
              />
            </Form.Item>
            <Form.Item label="Password" name="password" required>
              <Input.Password
                className="custom-input"
                placeholder="Input password"
                iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                status={hasError ? 'error' : ''}
                onKeyUp={handleKeyUp}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" className="login-btn" onClick={onSubmit}>Log In</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <aside className="content background"/>
    </div>
  )
}
