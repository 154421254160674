import { AutoComplete, Checkbox, DatePicker, Form, FormInstance, Input, InputNumber, Select, UploadFile } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { DiscountTypeList } from 'shame/discount';
import { Modal, Upload } from 'antd';
import { UploadButton } from 'common/UploadButton';
import { useEffect } from 'react';
import { UploadChangeParam } from 'antd/es/upload';
import { DiscountCategoriesList } from 'shame/discountCategories';
import { formRules } from './consts/formRules';

interface DiscountFormProps {
  form: FormInstance;
  options: Array<{ label: string, value: string }>;
  onSearch: (option: string) => void;
  image?: string;
  fileList: UploadFile[],
  previewOpen: boolean,
  previewImage: string,
  previewTitle: string,
  openPreview: (file: UploadFile) => void,
  closePreview: () => void,
  setFileList: (list: UploadFile[]) => void,
  handleChange: (info: UploadChangeParam<UploadFile<any>>) => void,
}

export const DiscountForm = ({
  form,
  options,
  onSearch,
  image,
  fileList,
  previewOpen,
  previewImage,
  previewTitle,
  openPreview,
  closePreview,
  setFileList,
  handleChange,
}: DiscountFormProps) => {


  useEffect(() => {
    if (image) {
      setFileList([{
        uid: '-1',
        name: 'Discount image',
        status: 'done',
        url: image
      }])
    } else {
      setFileList([])
    }
  }, [image, setFileList])

  return (
    <Form
      layout="vertical"
      form={form}
      style={{marginTop: '24px'}}
    >
      <Form.Item label="Partner Company" name="companyName" required>
         <AutoComplete
           options={options}
           style={{ width: 200 }}
           onSearch={(option) => onSearch(option)}
         />
      </Form.Item>
      <Form.Item label="Category" name="category" rules={formRules.category}>
        <Select
          allowClear
          options={DiscountCategoriesList}
        />
      </Form.Item>
      <Form.Item label="Offering" name="offering" rules={formRules.offering}>
        <Input />
      </Form.Item>
      <div className="row-wrapper">
        <Form.Item label="Discount" name="discount" rules={formRules.discount}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Discount Type" name="discountType" rules={formRules.discountType}>
          <Select
               allowClear
               options={DiscountTypeList}
             />
        </Form.Item>
        <Form.Item label="Rank" name="rank" rules={formRules.rank}>
          <InputNumber />
        </Form.Item>
      </div>
      <div className="row-wrapper">
        <Form.Item label="Discount Code" name="discountCode" rules={formRules.discountCode}>
          <Input />
        </Form.Item>
        <Form.Item label="Expiry Date" name="expiryDate">
          <DatePicker format="MM/DD/YYYY" />
        </Form.Item>
        <Form.Item label="Code Link" name="codeLink" rules={formRules.codeLink}>
          <Input />
        </Form.Item>
      </div>
      <Form.Item label="Offering Description" name="description" rules={formRules.description}>
        <TextArea rows={3} placeholder="Placeholder"/>
      </Form.Item>
      <Form.Item name="isFeatured" valuePropName="checked">
        <Checkbox>Show discount in "Featured"?</Checkbox>
      </Form.Item>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={openPreview}
        onChange={handleChange}
        beforeUpload={() => {
          return false;
        }}
      >
        {fileList.length >= 1 ? null : UploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={closePreview}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Form>
  )
}
