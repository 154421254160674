import { Avatar, Button, Form, FormInstance, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './Users.scss';
import { useMemo, useState } from 'react';
import { formRules } from './consts/formRules';

interface UserFormProps {
  form: FormInstance;
  profileImg: string | undefined;
  onSendEmail: () => void;
  onSendPassword: () => void;
}

export const UserForm = ({
  form,
  profileImg,
  onSendEmail,
  onSendPassword
}: UserFormProps) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formValues, setFormValues] = useState({});

  const isEmailButtonDisabled = useMemo(() => {
    return !(form.getFieldValue('newEmail'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue('newEmail')]);

  const isPasswordButtonDisabled = useMemo(() => {
    return !(form.getFieldValue('newPassword')) || form.getFieldValue('newPassword').length < 8;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue('newPassword')]);

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={(_, allValues) => {
        setFormValues(allValues);
      }}
    >
      <div className="profile-img-wrapper">
        <Avatar shape="square" size={64} src={profileImg} icon={<UserOutlined />} />
      </div>
      <div className="row-wrapper">
        <Form.Item label="First Name" name="firstName" required>
          <Input disabled />
        </Form.Item>
        <Form.Item label="Last Name" name="lastName" required>
          <Input disabled />
        </Form.Item>
      </div>
      <div className="row-wrapper">
        <Form.Item label="Phone" name="phone" required>
          <Input disabled />
        </Form.Item>
        <Form.Item label="Job Title" name="jobTitle" required>
          <Input disabled />
        </Form.Item>
      </div>
      <div className="row-wrapper">
        <Form.Item label="City" name="city" required>
          <Input disabled />
        </Form.Item>
        <Form.Item label="State" name="state" required>
          <Input disabled />
        </Form.Item>
        <Form.Item label="Country" name="country" required>
          <Input disabled />
        </Form.Item>
      </div>
      <Form.Item label="New Email" name="newEmail" validateTrigger='onInput' rules={formRules.email}>
        <div className="send-wrapper">
          <Input value={form.getFieldValue('newEmail')} type='email' />
          <Button
            type="primary"
            className="user-send-btn"
            onClick={onSendEmail}
            disabled={isEmailButtonDisabled}>Send</Button>
        </div>
      </Form.Item>
      <Form.Item label="New Password" name="newPassword" validateTrigger='onInput' rules={formRules.password}>
        <div className="send-wrapper">
          <Input />
          <Button
            type="primary"
            className="user-send-btn"
            onClick={onSendPassword}
            disabled={isPasswordButtonDisabled}>Send</Button>
        </div>
      </Form.Item>
    </Form>
  )
}
