import { ColumnsType } from 'antd/es/table';
import { Company } from 'types/data';
import { Button } from 'antd';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Remove } from 'assets/icons/remove.svg';
import Logo from 'assets/logo.png';
import { getDate } from 'common/utils';

export const OrganizationColumns= (
  editCallback: (item: Company) => void,
  deleteCallback: (item: Company) => void
  ): ColumnsType<Company> => ([
  {
    title: 'Company',
    dataIndex: 'name',
    fixed: true
  },
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Logo',
    dataIndex: 'logo',
    render: (logo: string) => {
      if (logo) {
        return <img className="table-img" src={logo} alt="COMPANY_LOGO"/>
      }
      return <img className="table-img" src={Logo} alt="FIBR_LOGO"/>
    }
  },
  {
    title: 'Website',
    dataIndex: 'website',
  },
  {
    title: 'Location',
    dataIndex: 'location',
  },
  {
    title: 'Creation Date',
    dataIndex: 'createdAt',
    render: (_, record) => getDate(record.createdAt)
  },
  {
    title: 'Last Updated',
    dataIndex: 'updatedAt',
    render: (_, record) => getDate(record.updatedAt)
  },
  {
    title: 'Actions',
    key: 'action',
    render: (item) => (
      <div className="actions-wrapper">
        <Button type="text" icon={<Edit className="edit"/>} onClick={() => editCallback(item)}/>
        <Button type="text" icon={<Remove className="remove"/>} onClick={() => deleteCallback(item)}/>
      </div>
    ),
    width: '80px',
    fixed: 'right'
  }
]);
