import { client } from 'api/client/client';
import { COURSES_URL } from 'api/urls';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { UploadFile } from 'antd';

export type UploadCourseImagePayload = {
  id: string;
  file: UploadFile;
}

const localUploadCourseImage = async ({ id, file }: UploadCourseImagePayload): Promise<Response> => {
  const formData = new FormData();
  formData.append('image', file.originFileObj as any);

  const response = await client({
    url: `${COURSES_URL}/image/${id}`,
    method: 'PUT',
    data: formData,
  });
  return response.data;
};

export const UploadCourseImage = (
  config?: UseMutationOptions<Response, AxiosError, UploadCourseImagePayload>
) => {
  return useMutation<Response, AxiosError, UploadCourseImagePayload>(localUploadCourseImage, config);
}
