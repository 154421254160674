import { Alert, Button, Modal, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useCSVUpload } from 'hooks/useCSVUpload';
import './UploadCSVModal.scss';
import { UploadJobCSV } from 'api/csv/uploadJobCSV';
import { useCallback, useEffect } from 'react';

export interface UploadCSVModalProps {
  isOpen: boolean;
  onCancel: () => void;
}

const { Dragger } = Upload;

export const UploadCSVModal = ({ isOpen, onCancel }: UploadCSVModalProps) => {
  const {
    uploadCSVProps,
    fileList,
    uploadLastSelected,
    setFileList
  } = useCSVUpload()

  const { mutate: uploadCSV, status, error } = UploadJobCSV({
    onSuccess: () => {
      setFileList([]);
    }
  });

  const handleUpload = () => {
    uploadCSV({ file: fileList[0] });
  }

  const handleCancel = () => {
    setFileList([]);
    onCancel();
  }

  useEffect(() => {
    setFileList([]);
  }, [setFileList, isOpen]);

  const renderStatus = useCallback(() => {
    if (status === 'success') {
      return (
        <Alert
          message="CSV file has been uploaded successfully"
          type="success"
          closable
        />
      )
    }

    if (status === 'error') {
      return (
        <Alert
          // @ts-ignore
          message={error?.response?.data?.message || 'CSV upload failed'}
          type="error"
          closable
        />
      )
    }
  }, [error, status]);

  return (
    <>
      <Modal
        open={isOpen}
        title="Upload CSV"
        footer={null}
        onCancel={handleCancel}>
         <Dragger
           {...uploadCSVProps}
           fileList={fileList}
           onChange={uploadLastSelected}
           beforeUpload={() => false}
         >
           <p className="ant-upload-drag-icon">
             <InboxOutlined />
           </p>
           <p className="ant-upload-text">Click or drag file to this area to select file</p>
         </Dragger>
        <div className="csv-btn-wrapper">
          <Button
            type="primary"
            onClick={handleUpload}
            className="custom-btn primary"
            disabled={Boolean(fileList.length !== 1)}
          >Upload CSV</Button>
        </div>
        {renderStatus()}
      </Modal>
    </>
  )
}
