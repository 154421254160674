const DiscountCategories = [
  'Equipment',
  'Education',
  'Gear',
  'Wellness',
  'Nutrition',
  'Other'
];

export const DiscountCategoriesList = DiscountCategories.map((item) => ({ value: item, label: item }))
