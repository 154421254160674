import { GetSkills, SkillsParams } from 'api/skills/getSkills';
import { useEffect, useState } from 'react';
import { defaultParams } from 'common/utils';
import { Skill } from 'types/data';

export const useSkills = () => {
  const [params, setParams] = useState<SkillsParams>(defaultParams);
  const [skills, setSkills] = useState<Skill[]>([]);
  const { data } = GetSkills(params);

  useEffect(() => {
    if(data) {
      setSkills(data.skills);
    } else {
      setSkills([]);
    }
  },[data])

  return {
    setSkillParams: setParams,
    skills
  }
}
