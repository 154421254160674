import { AutoComplete, Checkbox, Form, FormInstance, Input, InputNumber, Select } from 'antd';
import { StatesList } from 'shame/states';
import { EmploymentTypeList } from 'shame/employmentType';
import { positionLevelList } from 'shame/positionLevel';
import { payTypeList, supplementPayTypeList } from 'shame/payType';
import TextArea from 'antd/es/input/TextArea';
import { JobCategoriesList } from 'shame/jobCategories';
import { CountryList } from 'shame/country';
import { Skill } from 'types/data';
import { formRules } from './consts/formRules';

interface JobFormProps {
  form: FormInstance;
  skills: Skill[];
  onSkillSearch: (option: string) => void
  companyOptions: Array<{ label: string, value: string }>;
  onSearch: (option: string) => void;
  onCompanySelect: (name: string) => void;
}

export const JobForm = ({ form, skills, onSkillSearch, companyOptions, onSearch, onCompanySelect }: JobFormProps) => {
  return (
    <Form
      layout="vertical"
      form={form}
      validateTrigger="onInput"
    >
      <div className="modal-section-header">General Info</div>
      <Form.Item label="Company" name="companyName" required rules={formRules.companyName}>
        <AutoComplete
          options={companyOptions}
          style={{ width: 200 }}
          onSearch={(option) => onSearch(option)}
          onSelect={onCompanySelect}
        />
      </Form.Item>
      <Form.Item label="Company Overview" name="companyOverview" rules={formRules.companyOverview}>
        <Input placeholder="Insert company overview" />
      </Form.Item>
      <div className="row-wrapper">
        <Form.Item label="City" name="city" required rules={formRules.city}>
          <Input placeholder="Company city" />
        </Form.Item>
        <Form.Item label="State" name="state" required rules={formRules.state}>
          <Select
            allowClear
            options={StatesList}
          />
        </Form.Item>
        <Form.Item label="Country" name="country" required rules={formRules.country}>
          <Select
            allowClear
            options={CountryList}
          />
        </Form.Item>
      </div>
      <div className="modal-section-header">Position & Experience</div>
      <Form.Item label="Job Title" name="jobTitle" rules={formRules.jobTitle}>
        <Input
          placeholder="Insert job title"
        />
      </Form.Item>
      <div className="row-wrapper">
        <Form.Item label="Employment Type" name="employmentType">
          <Select
            allowClear
            options={EmploymentTypeList}
          />
        </Form.Item>
        <Form.Item label="Experience (in years)" name="experience" rules={formRules.experience}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Position Level" name="positionLevel" rules={formRules.positionLevel}>
          <Select
            allowClear
            options={positionLevelList}
          />
        </Form.Item>
      </div>
      <div className="row-wrapper">
        <Form.Item label="Pay Type" name="payType">
          <Select
            allowClear
            options={payTypeList}
          />
        </Form.Item>
        <Form.Item label="Pay Rate (min)" name="payRateMin" rules={formRules.payRateMin}>
          <InputNumber className="custom-input" />
        </Form.Item>
        <Form.Item label="Pay Rate (max)" name="payRateMax" rules={formRules.payRateMax}>
          <InputNumber className="custom-input" />
        </Form.Item>
        <Form.Item label="Supplemental pay" name="supplementalPay">
          <Select
            mode="multiple"
            allowClear
            options={supplementPayTypeList}
          />
        </Form.Item>
      </div>
      <div className="modal-section-header">Skills & Requirements</div>
      <Form.Item label="Job Categories" name="jobCategories">
        <Select
          mode="multiple"
          allowClear
          options={JobCategoriesList}
        />
      </Form.Item>
      <Form.Item label="Required Skills" name="requiredSkills">
        <Select
          mode="multiple"
          allowClear
          options={(skills).map((skill: Skill) => ({
            value: skill.id,
            label: skill.name
          }))}
          filterOption={false}
          onSearch={(option) => onSkillSearch(option)}
        />
      </Form.Item>
      <Form.Item label="Duties & Responsibilities" name="dutiesResponsibilities" rules={formRules.dutiesResponsibilities}>
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item label="Job Requirements" name="jobRequirements" rules={formRules.jobRequirements}>
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item label="Benefits" name="benefits" rules={formRules.benefits}>
        <TextArea rows={3} />
      </Form.Item>
      <div className="modal-section-header">Additional Info</div>
      <Form.Item label="Hiring Manager email" name="hiringManagerMail" required rules={formRules.hiringManagerMail}>
          <Input />
      </Form.Item>
      <Form.Item name="isRemote" valuePropName="checked">
        <Checkbox>This job is remote</Checkbox>
      </Form.Item>
      <Form.Item name="isFeatured" valuePropName="checked">
        <Checkbox>Should job listing be in "Featured"?</Checkbox>
      </Form.Item>
      <Form.Item label="Rank" name="rank" rules={formRules.rank}>
          <InputNumber />
      </Form.Item>
    </Form>
  )
}
