import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { User } from 'types/data';
import { defaultParams, mapResponseToUsers } from 'common/utils';
import { removeToken } from 'hooks/auth/utils';
import { Path } from 'routes';
import { GetUser, GetUsers, UsersParams } from 'api/users/getUsers';
import { useFilterContext } from 'core/FilterContext';
import { UpdateUser } from 'api/users/updateUser';
import { DeleteUsers } from 'api/users/deleteUsers';
import { useQueryClient } from 'react-query';

export const useUsers = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userName } = useFilterContext();
  const [params, setParams] = useState<UsersParams>(defaultParams)
  const [total, setTotal] = useState<number>();
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [users, setUsers] = useState<User[]>([])
  const { data, isLoading, error, refetch } = GetUsers(params);
  const { data: userData, isLoading: isUserLoading, error: userError } = GetUser({ id: selectedUserId });

  const { mutateAsync: updateUser } = UpdateUser()

  const { mutate: deleteUsers } = DeleteUsers({
    onSuccess: () => {
      invalidateCache();
      refetch();
    },
  });

  const invalidateCache = () => {
    queryClient.invalidateQueries(['users']);
    queryClient.invalidateQueries(['user', { id: null }]);
    navigate(Path.Users);
  }

  useEffect(() => {
    if(data) {
      setUsers(mapResponseToUsers(data.users));
      setTotal(data.count);
    }

    if(error) {
      removeToken();
      navigate(Path.Login);
    }
  }, [data, error, navigate, setUsers])

  useEffect(() => {
    if (userName) {
      return setParams((prev) => ({ ...prev, name: userName }));
    }
    setParams(defaultParams);
  }, [userName])

  return {
    isLoading,
    users,
    params,
    setParams,
    total,
    userData,
    isUserLoading,
    userError,
    setSelectedUserId,
    updateUser,
    deleteUsers
  }
}
