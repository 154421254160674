import { COMPANIES_URL } from 'api/urls';
import { client } from 'api/client/client';
import { AddEditCompanyPayload } from 'types/data';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export type UpdateCompanyPayload = {
  id: string;
  payload: AddEditCompanyPayload;
}

const localUpdateCompany = async ({ id, payload }: UpdateCompanyPayload): Promise<Response> => {
  const response = await client({
    url: `${COMPANIES_URL}/${id}`,
    method: 'PUT',
    data: payload,
  });
  return response.data;
};

export const UpdateCompany =(
  config?: UseMutationOptions<Response, AxiosError, UpdateCompanyPayload>
) => {
  return useMutation<Response, AxiosError, UpdateCompanyPayload>(localUpdateCompany, config);
}
