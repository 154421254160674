import { COURSES_URL } from 'api/urls';
import { client } from 'api/client/client';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { AddEditCoursePayload } from 'types/data';

interface AddCourseResponse extends Response{
  message: string;
  data: {
    id: number;
  }
}

const localAddCourse = async ({ ...input }: AddEditCoursePayload): Promise<AddCourseResponse> => {
  const response = await client({
    url: `${COURSES_URL}`,
    method: 'POST',
    data: input,
  });
  return response.data;
};

export const AddCourse =(
  config?: UseMutationOptions<AddCourseResponse, AxiosError, AddEditCoursePayload>
) => {
  return useMutation<AddCourseResponse, AxiosError, AddEditCoursePayload>(localAddCourse, config);
}
