import type { AxiosError } from 'axios';

import { useQuery, UseQueryOptions } from 'react-query';
import { client, getQueryKey } from 'api/client/client';
import { SKILLS_URL } from 'api/urls';
import { Skill } from 'types/data';

export type SkillsParams = {
  page?: number;
  limit?: number;
  name?: string;
};

type Response = {
  count: number;
  skills: Array<Skill>;
}

const localGetSkills = async({ ...params }: SkillsParams): Promise<Response> => {
  const response = await client({
    url: SKILLS_URL,
    method: 'GET',
    params,
  });
  return response.data.data;
}

export const GetSkills = (
  params: SkillsParams,
  config?: UseQueryOptions<Response, AxiosError>
)  => {
  const queryKey = [getQueryKey('skills'), params.page, params.name];
  return useQuery<Response, AxiosError>(
    queryKey,
    () => localGetSkills(params),
    config,
  );
}
