import { TableProps } from 'antd/es/table/InternalTable';
import { Course } from 'types/data';

export const CourseTableProps: TableProps<Course> = {
  bordered: false,
  size: 'large',
  showHeader: true,
  tableLayout: 'auto',
  pagination: false,
  scroll: { x: '100vw' },
  rowSelection: {},
};
