
export function getSessionStorageItem(key: string): string | undefined {
  const item = sessionStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return undefined;
}

export function setSessionStorageItem<T>(key: string, payload: T): void {
  const parsedItem = JSON.stringify(payload)

  sessionStorage.setItem(key, parsedItem);
}

export function removeSessionStorageItem<T>(
  key: string
): T | undefined | Error {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    return undefined;
  }
}
