import { getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from 'core/sessionStorage';

const TOKEN = 'token';

const getItem = (key: string): string | undefined => {
  return getSessionStorageItem(key);
}

const setItem = <T>(key: string, value: T) => {
  setSessionStorageItem(key, value);
}

const removeItem = (key: string) => {
  removeSessionStorageItem(key);
}

export const getToken = () => getItem(TOKEN);
export const removeToken = () => removeItem(TOKEN);
export const setToken = (value: string) => setItem<string>(TOKEN, value);
