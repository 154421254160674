import { useState } from 'react';
import { useAuth } from 'api/auth/useAuth';
import { useNavigate } from 'react-router-dom';
import { Path } from 'routes';

export const useLogin = () => {
  const navigate = useNavigate();
  const { useAdminLogin, signIn } = useAuth();

  const [hasError, setHasError] = useState(false)

   const { mutate: login, isLoading } = useAdminLogin({
      onSuccess: (response) => {
        signIn(response.data.token);
        navigate(Path.Jobs);
      },
      onError: () => {
        setHasError(true);
      },
    })

  return {
    hasError,
    login,
    isLoading
  }
}
