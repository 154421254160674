import { Input } from 'antd'
import './Header.scss'
import { useLocation } from 'react-router-dom';
import { Path } from 'routes';
import { useFilterContext } from 'core/FilterContext';
import { useEffect, useState } from 'react';

const { Search } = Input;

export const Header = () => {
  const location = useLocation();
  const [inputValue, setInputValue] = useState('')
  const {
    updateCompanyFilter,
    updateCourseFilter,
    updateDiscountFilter,
    updateJobFilter,
    updateUserFilter,
    clearFilters
  } = useFilterContext()

  const getSearchText: { [K in Path]: string } = {
    [Path.Jobs]: 'Search for job titles',
    [Path.Education]: 'Search for education listings',
    [Path.Discounts]: 'Search for discounts',
    [Path.Organizations]: 'Search for organizations',
    [Path.Users]: 'Search for users',
    [Path.Login]: '',
    [Path.Root]: '',
  }

  const onSearch = (input: string) => {
    if (location.pathname === Path.Jobs) {
      updateJobFilter(input);
    }
    if (location.pathname === Path.Discounts) {
      updateDiscountFilter(input);
    }
    if (location.pathname === Path.Education) {
      updateCourseFilter(input);
    }
    if (location.pathname === Path.Organizations) {
      updateCompanyFilter(input);
    }
    if (location.pathname === Path.Users) {
      updateUserFilter(input);
    }
  }

  useEffect(() => {
    setInputValue('');
    clearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <div className="header-wrapper">
      <Search
        placeholder={getSearchText[location.pathname as Path]}
        size="large"
        className="input"
        onSearch={onSearch}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </div>
  )
}
