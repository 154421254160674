import { DISCOUNTS_URL } from 'api/urls';
import { client } from 'api/client/client';
import { AddEditDiscountPayload } from 'types/data';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export type UpdateDiscountPayload = {
  id: string;
  payload: AddEditDiscountPayload;
}

const localUpdateDiscount = async ({ id, payload }: UpdateDiscountPayload): Promise<Response> => {
  const response = await client({
    url: `${DISCOUNTS_URL}/${id}`,
    method: 'PUT',
    data: payload,
  });
  return response.data;
};

export const UpdateDiscount =(
  config?: UseMutationOptions<Response, AxiosError, UpdateDiscountPayload>
) => {
  return useMutation<Response, AxiosError, UpdateDiscountPayload>(localUpdateDiscount, config);
}
