import { DISCOUNTS_URL } from 'api/urls';
import { client } from 'api/client/client';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export type DeleteDiscountsInput = {
  discountIds: Array<number>
}

const localDeleteDiscounts = async ({ ...input }: DeleteDiscountsInput): Promise<Response> => {
  const response = await client({
    url: `${DISCOUNTS_URL}`,
    method: 'DELETE',
    data: input,
  });
  return response.data;
};

export const DeleteDiscounts =(
  config?: UseMutationOptions<Response, AxiosError, DeleteDiscountsInput>
) => {
  return useMutation<Response, AxiosError, DeleteDiscountsInput>(localDeleteDiscounts, config);
}
