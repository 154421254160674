import { client } from 'api/client/client';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { JOBS_URL } from 'api/urls';
import { AddEditJobPayload } from 'types/job';

export type UpdateJobPayload = {
  id: string;
  payload: AddEditJobPayload;
}

const localUpdateJob = async ({ id, payload }: UpdateJobPayload): Promise<Response> => {
  const response = await client({
    url: `${JOBS_URL}/${id}`,
    method: 'PUT',
    data: payload,
  });
  return response.data;
};

export const UpdateJob =(
  config?: UseMutationOptions<Response, AxiosError, UpdateJobPayload>
) => {
  return useMutation<Response, AxiosError, UpdateJobPayload>(localUpdateJob, config);
}
