import { USERS_URL } from 'api/urls';
import { client } from 'api/client/client';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export type DeleteUsersInput = {
  userIds: Array<number>
}

const localDeleteUsers = async ({ ...input }: DeleteUsersInput): Promise<Response> => {
  const response = await client({
    url: `${USERS_URL}`,
    method: 'DELETE',
    data: input,
  });
  return response.data;
};

export const DeleteUsers =(
  config?: UseMutationOptions<Response, AxiosError, DeleteUsersInput>
) => {
  return useMutation<Response, AxiosError, DeleteUsersInput>(localDeleteUsers, config);
}
