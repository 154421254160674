import { client } from 'api/client/client';
import { COMPANIES_URL } from 'api/urls';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { UploadFile } from 'antd';

export type UploadCompanyImagePayload = {
  id: string;
  file: UploadFile;
}

const localUploadCompanyImage = async ({ id, file }: UploadCompanyImagePayload): Promise<Response> => {
  const formData = new FormData();
  formData.append('image', file.originFileObj as any);

  const response = await client({
    url: `${COMPANIES_URL}/image/${id}`,
    method: 'PUT',
    data: formData,
  });
  return response.data;
};

export const UploadCompanyImage = (
  config?: UseMutationOptions<Response, AxiosError, UploadCompanyImagePayload>
) => {
  return useMutation<Response, AxiosError, UploadCompanyImagePayload>(localUploadCompanyImage, config);
}
