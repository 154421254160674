export enum EmploymentType {
  FULL_TIME = 'full_time',
  PART_TIME = 'part_time',
  FREELANCE = 'freelance',
  CONTRACT = 'contract',
  INTERNSHIP = 'internship',
}

export const EmploymentTypeList = [
  {value: EmploymentType.FULL_TIME, label: 'Full time'},
  {value: EmploymentType.PART_TIME, label: 'Part time'},
  {value: EmploymentType.FREELANCE, label: 'Freelance'},
  {value: EmploymentType.CONTRACT, label: 'Contract'},
  {value: EmploymentType.INTERNSHIP, label: 'Internship'},
]
