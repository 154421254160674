import { MenuProps } from 'antd';
import { ReactComponent as Jobs } from 'assets/icons/jobs.svg';
import { ReactComponent as Discounts } from 'assets/icons/discount.svg';
import { ReactComponent as Education } from 'assets/icons/education.svg';
import { ReactComponent as Organizations } from 'assets/icons/organizations.svg';
import { ReactComponent as Users } from 'assets/icons/users.svg';
import { ReactComponent as Analytics } from 'assets/icons/analytics.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';

export const MenuItems: MenuProps['items'] = [
  {
    key: 0,
    icon: <Jobs />,
    label: 'Job Listings',
  },
  {
    key: 1,
    icon: <Discounts />,
    label: 'Discounts',
  },
  {
    key: 2,
    icon: <Education />,
    label: 'Education',
  },
  {
    key: 3,
    icon: <Organizations />,
    label: 'Organizations',

  },
  {
    key: 4,
    icon: <Users />,
    label: 'For Users',
  },
  {
    key: 5,
    icon: <Analytics />,
    label: 'Analytics'

  },
]

export const LogoutItem: MenuProps['items'] = [
  {
    key: 6,
    icon: <Logout />,
    label: 'Logout'
  }
]
