import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Layout as AntdLayout, Menu } from 'antd';
import { LogoutItem, MenuItems } from 'layout/menuItems';
import Logo from 'assets/logo.png';
import './Layout.scss';
import { Header } from 'components/Header/Header';
import { Navigation, Path } from 'routes';
import { removeToken } from 'hooks/auth/utils';
import { useAuth } from 'api/auth/useAuth';

const { Content, Sider } = AntdLayout;

export const Layout = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useAuth();

  const getDefaultSelectedKey = (): string[] => {
    return [Navigation.indexOf(location.pathname as Path).toString()];
  }

  const handleMenuClick = ({ key }: any) => {
    if (Navigation[key]) {
      navigate(Navigation[key]);
    }
  }

  const handleLogout = () => {
    removeToken()
    return navigate(Path.Login);
  }

  const getSelectedKeys = (): string[] => {
    return [Navigation.indexOf(location.pathname as Path).toString()]
  }

  if (!isLoggedIn) {
    return <Navigate to={Path.Login} replace />;
  }

  return (
    <AntdLayout hasSider style={{ backgroundColor: 'unset', height: '100vh' }}>
      <Sider
        theme="light"
        style={{
          height: '100vh',
          position: 'fixed',
          padding: '32px',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="logo-wrapper">
          <img src={Logo} alt="FIBR LOGO"/>
        </div>
        <Menu theme="light"
              mode="inline"
              defaultSelectedKeys={getDefaultSelectedKey()}
              items={MenuItems}
              onClick={handleMenuClick}
              selectedKeys={getSelectedKeys()}
        />
        <div className="logout-wrapper">
          <Menu theme="light" mode="inline" items={LogoutItem} onClick={handleLogout}/>
        </div>
      </Sider>
      <AntdLayout className="site-layout" style={{ marginLeft: 305 }}>
        <Content style={{ backgroundColor: 'unset' }}>
          <Header/>
          <Outlet/>
        </Content>
      </AntdLayout>
    </AntdLayout>
  )
}
