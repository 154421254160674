import { PageTitle } from 'components/PageTitle/PageTitle';
import { Table } from 'components/Table/Table';
import { useUsers } from 'hooks/useUsers';
import { User, UserRecordResponse } from 'types/data';
import { UserColumns } from 'components/Users/consts/columns';
import { UserTableProps } from 'components/Users/consts/tableProps';
import { Path } from 'routes';
import { Alert, Form, Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserForm } from 'components/Users/UserForm';
import { AxiosError } from 'axios';
import { ValidationErrorResponse } from 'types/common';
import { formatErrors } from 'common/utils';

export const Users = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [error, setError] = useState<string | null>(null);
  const {
    users,
    isLoading,
    params,
    setParams,
    total,
    userData,
    setSelectedUserId,
    updateUser,
    deleteUsers
  } = useUsers();

  const handleAccept = () => {

  }

  const handlePageChange = (nextPage: number, pageSize: number) => {
    setParams((prev) => ({
      ...prev,
      page: nextPage,
      limit: pageSize
    }));
  }

  const handleDelete = (item: User) => {
    deleteUsers({ userIds: [item.id]});
  }

  const handleEdit = (item: User): any => {
    setSelectedUserId(+item.id);
    navigate(item.id.toString(), { relative: 'route' });
    setIsModalOpen(true)
  }

  const handleEmail = async () => {
    if (!userData) {
      return;
    }
    const email = form.getFieldValue('newEmail');

    try {
      await updateUser({
        id: userData?.id,
        payload: {
          email,
          password: null,
        },
      });
    } catch (error) {
      const err = error as AxiosError<ValidationErrorResponse>;
      if (err.response?.data.error) {
        setError(formatErrors(err.response?.data.error));
        return;
      } 
      setError(err.response?.data.message || 'Something went wrong');
    }
    form.setFieldValue('newEmail', '');
  }

  const handlePassword = async () => {
    if (!userData) {
      return;
    }
    const password = form.getFieldValue('newPassword');

    try {
      await updateUser({
        id: userData?.id,
        payload: {
          password,
          email: null,
        },
      });
    } catch (error) {
      const err = error as AxiosError<ValidationErrorResponse>;
      if (err.response?.data.error) {
        setError(formatErrors(err.response?.data.error));
        return;
      } 
      setError(err.response?.data.message || 'Something went wrong');
    }
    form.setFieldValue('newPassword', '');
  }

  const setFormValues = useCallback((data: UserRecordResponse) => {
    form.setFieldsValue({
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      jobTitle: data.jobTitle,
      city: data.location?.city,
      state: data.location?.state,
      country: data.location?.country
    });
  }, [form]);

  useEffect(() => {
    if (urlParams.id) {
      setSelectedUserId(+urlParams.id);
      setIsModalOpen(true);
    }
    // Component did mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userData) {
      setFormValues(userData)
    }
  }, [form, userData, setFormValues])

  return (
    <div className="page-wrapper">
      <div className="page-header">
        <PageTitle title="Users" />
      </div>
      <div className="page-content">
        <Table<User>
          columns={UserColumns(handleEdit, handleDelete)}
          tableProps={UserTableProps}
          data={users}
          isLoading={isLoading}
          current={params.page}
          total={total}
          pageSize={params.limit}
          onPageChange={handlePageChange}
        />
      </div>
      <Modal
        title="Edit User"
        open={isModalOpen}
        onOk={handleAccept}
        onCancel={() => setIsModalOpen(false)}
        afterClose={() => navigate(Path.Users)}
      >
        <div className="modal-content">
          <UserForm
            form={form}
            profileImg={userData?.profileImg}
            onSendEmail={handleEmail}
            onSendPassword={handlePassword}
          />
          {error && (
            <Alert
              message={error}
              type="error"
              closable
              onClose={() => setError(null)}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}
