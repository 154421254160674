import type { AxiosError } from 'axios';

import { useQuery } from 'react-query';
import { client } from 'api/client/client';
import { ADMINS_URL } from 'api/urls';
import { UserRecordResponse } from 'types/data';

export type AdminParams = {
  page: number;
  limit: number
  companyId?: string;
};

type Response = {
  count: number;
  users: UserRecordResponse[];
}


const localGetAdmins= async({ companyId } : AdminParams): Promise<Response> => {
  const response = await client({
    url: `${ADMINS_URL}/${companyId}/admins`,
    method: 'GET',
  });
  return response.data.data;
}

export const GetAdmins = (
  params: AdminParams,
) => {
  return useQuery<Response, AxiosError>({
    queryKey: ['admins', params.companyId, params.page],
    queryFn: () => localGetAdmins(params),
    enabled: !!params.companyId
  });
}
