import { Rule } from "antd/es/form";

export const formRules: Record<string, Rule[]> = {
    title: [
        {
            required: true,
            min: 1,
            max: 200,
        }
    ],
    description: [
        {
            min: 1,
            max: 500,
        }
    ],
    code: [
        {
            min: 1,
            max: 200,
        }
    ],
    discount: [
        {
            required: true,
            min: 1,
            type: 'number',
            message: 'Please enter discount value',
        }
    ],
    discountType: [
        {
            required: true,
        }
    ],
    externalLink: [
        {
            required: true,
            min: 1,
            max: 300,
            type: 'url',
            message: 'Please enter a valid URL',
        }
    ],
    rank: [
        {
            type: 'number',
            min: 0,
        }
    ],
    details: [
        {
            min: 1,
            max: 1000
        }
    ],
    category: [
        {
            required: true,
            message: "Category is required",
        }
    ],
}