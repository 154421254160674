import { AddEditCoursePayload } from 'types/data';
import { client } from 'api/client/client';
import { COURSES_URL } from 'api/urls';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';


export type UpdateCoursePayload = {
  id: string;
  payload: AddEditCoursePayload;
}

const localUpdateCourse = async ({ id, payload }: UpdateCoursePayload): Promise<Response> => {
  const response = await client({
    url: `${COURSES_URL}/${id}`,
    method: 'PUT',
    data: payload,
  });
  return response.data;
};

export const UpdateCourse =(
  config?: UseMutationOptions<Response, AxiosError, UpdateCoursePayload>
) => {
  return useMutation<Response, AxiosError, UpdateCoursePayload>(localUpdateCourse, config);
}
