import { ColumnsType } from 'antd/es/table';
import { Discount } from 'types/data';
import { Button } from 'antd';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Remove } from 'assets/icons/remove.svg';
import { getDate } from 'common/utils';

export const DiscountColumns = (
  editCallback: (item: Discount) => void,
  deleteCallback: (item: Discount) => void,
  ): ColumnsType<Discount> => ([
  {
    title: 'Company',
    dataIndex: 'companyName',
    fixed: true
  },
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Headline',
    dataIndex: 'title',
  },
  {
    title: 'Category',
    dataIndex: 'category',
  },
  {
    title: 'Link/Code',
    dataIndex: 'code',
  },
  {
    title: 'Offering',
    dataIndex: 'discount',
  },
  {
    title: 'Posting Date',
    dataIndex: 'createdAt',
    render: (_, record) => getDate(record.createdAt)
  },
  {
    title: 'Last Modified',
    dataIndex: 'updatedAt',
    render: (_, record) => getDate(record.updatedAt)
  },
  {
    title: 'Rank',
    dataIndex: 'rank',
  },
  {
    title: 'Actions',
    key: 'action',
    render: (item) => (
      <div className="actions-wrapper">
        <Button type="text" icon={<Edit className="edit" />} onClick={() => editCallback(item)} />
        <Button type="text" icon={<Remove className="remove" />} onClick={() => deleteCallback(item)} />
      </div>
    ),
    width: '80px',
    fixed: 'right'
  }
]);
