import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AddEditCompanyPayload, Company } from 'types/data';
import { defaultParams, mapResponseToCompanies } from 'common/utils';
import { removeToken } from 'hooks/auth/utils';
import { Path } from 'routes';
import { CompaniesParams, GetCompanies, GetCompany } from 'api/companies/getCompanies';
import { useFilterContext } from 'core/FilterContext';
import { useQueryClient } from 'react-query';
import { AddCompany } from 'api/companies/addComapny';
import { DeleteCompanies } from 'api/companies/deleteCompanies';
import { UpdateCompany, UpdateCompanyPayload } from 'api/companies/updateCompany';
import { usePictureUpload } from 'hooks/usePictureUpload';
import { UploadCompanyImage } from 'api/companies/uploadCompanyImage';

export const useOrganizations = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { companyName } = useFilterContext();
  const [params, setParams] = useState<CompaniesParams>(defaultParams)
  const [total, setTotal] = useState<number>();
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>();
  const [companies, setCompanies] = useState<Company[]>([])
  const { data, isLoading, error, refetch } = GetCompanies(params);
  const { data: companyData, isLoading: companyIsLoading, error: companyError } = GetCompany({ id: selectedCompanyId });

  const {
    fileList,
    previewOpen,
    previewImage,
    previewTitle,
    openPreview,
    closePreview,
    setFileList,
    handleChange,
  } = usePictureUpload()

  const { mutate: uploadImage } = UploadCompanyImage({
      onSuccess: () => {
      },
    }
  );

  const { mutateAsync: localAddCompany } = AddCompany({
    onSuccess: (response) => {
      uploadImage({id: response.data.id.toString(), file: fileList[0] });
      invalidateCache();
      setFileList([]);
    },
  });

  const { mutate: deleteCompanies } = DeleteCompanies({
    onSuccess: () => {
      invalidateCache();
      refetch();
    },
  });

  const { mutateAsync: localUpdateCompany } = UpdateCompany({
    onSuccess: () => {
      invalidateCache();
    },
  });

  const invalidateCache = () => {
    queryClient.invalidateQueries(['companies']);
    queryClient.invalidateQueries(['company', { id: null }]);
    navigate(Path.Organizations);
  }

  const addCompany = async (input: AddEditCompanyPayload) => {
    await localAddCompany(input);
  }

  const updateCompany = async (input: UpdateCompanyPayload) => {
    await localUpdateCompany(input);
    uploadImage({id: input.id, file: fileList[0]});
    setFileList([]);
  }
  useEffect(() => {
    if(data) {
      setCompanies(mapResponseToCompanies(data.companies));
      setTotal(data.count);
    }

    if(error) {
      removeToken();
      navigate(Path.Login);
    }
  }, [data, error, navigate, setCompanies])

  useEffect(() => {
    if (companyName) {
      return setParams((prev) => ({ ...prev, name: companyName }));
    }
    setParams(defaultParams);
  }, [companyName])

  return {
    isLoading,
    companies,
    params,
    setParams,
    total,
    companyData,
    companyIsLoading,
    companyError,
    setSelectedCompanyId,
    addCompany,
    updateCompany,
    deleteCompanies,
    fileList,
    previewOpen,
    previewImage,
    previewTitle,
    openPreview,
    closePreview,
    setFileList,
    handleChange,
  }
}
