import { Pagination as AntdPagination, PaginationProps as AntdPaginationProps } from 'antd';
import './Pagination.scss'

export interface PaginationProps {
  total: number;
  current: number;
  pageSize: number;
  onChange: (nextPage: number, pageSize: number) => void;
}

export const Pagination = ({ total, current, pageSize, onChange }: PaginationProps) => {
  const itemRender: AntdPaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return <a className="pagination-previous">Previous</a>;
    }
    if (type === 'next') {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return <a className="pagination-next">Next</a>;
    }
    return originalElement;
  };

  return (
    <div className="pagination-wrapper">
      <AntdPagination simple total={total} itemRender={itemRender} current={current} onChange={onChange}
                      pageSize={pageSize} />
    </div>
  )
}
