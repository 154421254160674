import { COMPANIES_URL } from 'api/urls';
import { client } from 'api/client/client';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export type DeleteCompaniesInput = {
  companyIds: Array<number>
}

const localDeleteCompanies = async ({ ...input }: DeleteCompaniesInput): Promise<Response> => {
  const response = await client({
    url: `${COMPANIES_URL}`,
    method: 'DELETE',
    data: input,
  });
  return response.data;
};

export const DeleteCompanies =(
  config?: UseMutationOptions<Response, AxiosError, DeleteCompaniesInput>
) => {
  return useMutation<Response, AxiosError, DeleteCompaniesInput>(localDeleteCompanies, config);
}
