import { useState } from 'react';
import { UploadFile, UploadProps } from 'antd';
import { getBase64 } from 'common/getBase64';
import { RcFile } from 'antd/es/upload';

export const usePictureUpload = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const closePreview = () => setPreviewOpen(false);

  const openPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadLastSelected: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList.slice(-1));

  return {
    fileList,
    previewOpen,
    previewImage,
    previewTitle,
    openPreview,
    closePreview,
    handleChange,
    setFileList,
    uploadLastSelected
  }

}
