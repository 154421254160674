
const JobCategories = [
  'Personal Training',
  'Group Fitness',
  'Member Services',
  'Management',
  'Sales and Marketing',
  'Operations',
  'Corporate',
  'Human Resources',
  'Kids Care',
  'Other'
]

export const JobCategoriesList = JobCategories.map((item) => ({
  label: item, value: item
}))
