import { ConfigProvider, Spin } from 'antd';
import { useAuth } from 'api/auth/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Path } from 'routes';
import './AppLoader.scss';

export const AppLoader = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth()

  useEffect(() => {
    if (isLoggedIn) {
      navigate(Path.Jobs);
    } else {
      navigate(Path.Login);
    }
  })

  return (
    <div className="loader-wrapper">
      <ConfigProvider theme={{
        token: {
          colorPrimary: '#18A000',
          colorPrimaryBg: '#EAFFDC'
        }
      }}>
        <Spin />
      </ConfigProvider>
    </div>
  )
}
