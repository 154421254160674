import { ColumnsType } from 'antd/es/table';
import { Course } from 'types/data';
import { Button } from 'antd';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Remove } from 'assets/icons/remove.svg';
import { getDate } from 'common/utils';

export const CourseColumns = (
  editCallback: (item: Course) => void,
  deleteCallback: (item: Course) => void
): ColumnsType<Course> => ([
  {
    title: 'Organization',
    dataIndex: 'organization',
    fixed: true
  },
  {
    title: 'Course',
    dataIndex: 'name',
  },
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Company',
    dataIndex: 'organization',
  },
  {
    title: 'Offering',
    dataIndex: 'discount',
  },
  {
    title: 'Posting Date',
    dataIndex: 'postingDate',
    render: (_, record) => getDate(record.createdAt)
  },
  {
    title: 'Last Modified',
    dataIndex: 'lastModified',
    render: (_, record) => getDate(record.updatedAt)
  },
  {
    title: 'Rank',
    dataIndex: 'rank',
  },
  {
    title: 'Actions',
    key: 'action',
    render: (item) => (
      <div className="actions-wrapper">
        <Button type="text" icon={<Edit className="edit" />} onClick={() => editCallback(item)} />
        <Button type="text" icon={<Remove className="remove" />} onClick={() => deleteCallback(item)} />
      </div>
    ),
    width: '80px',
    fixed: 'right'
  }
]);
