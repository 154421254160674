import { client } from 'api/client/client';
import { DISCOUNTS_URL } from 'api/urls';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { UploadFile } from 'antd';

export type UploadDiscountImagePayload = {
  id: string;
  file: UploadFile;
}

const localUploadDiscountImage = async ({ id, file }: UploadDiscountImagePayload): Promise<Response> => {
  const formData = new FormData();
  formData.append('image', file.originFileObj as any);

  const response = await client({
    url: `${DISCOUNTS_URL}/image/${id}`,
    method: 'PUT',
    data: formData,
  });
  return response.data;
};

export const UploadDiscountImage = (
  config?: UseMutationOptions<Response, AxiosError, UploadDiscountImagePayload>
) => {
  return useMutation<Response, AxiosError, UploadDiscountImagePayload>(localUploadDiscountImage, config);
}
