import { CourseRecordResponse, CourseResponse } from 'types/data';
import type { AxiosError } from 'axios';

import { useQuery, UseQueryOptions } from 'react-query';
import { client, getQueryKey } from 'api/client/client';
import { COURSES_URL } from 'api/urls';

export type CoursesParams = {
  page?: number;
  limit?: number
  title?: string;
};

type Response = {
  count: number;
  courses: CourseResponse[];
}

const localGetCourses = async({ ...params }: CoursesParams): Promise<Response> => {
  const response = await client({
    url: COURSES_URL,
    method: 'GET',
    params,
  });
  return response.data.data;
}

export const GetCourses = (
  params: CoursesParams,
  config?: UseQueryOptions<Response, AxiosError>
)  => {
  const queryKey = [getQueryKey('courses'), params.page, params.title];
  return useQuery<Response, AxiosError>(
    queryKey,
    () => localGetCourses(params),
    config
  );
}

export type CourseRecordPrams = { id: number | undefined};


const localGetCourse = async({id} : CourseRecordPrams): Promise<CourseRecordResponse> => {
  const response = await client({
    url: `${COURSES_URL}/${id}`,
    method: 'GET',
  });
  return response.data.data;
}

export const GetCourse = (
  params: CourseRecordPrams,
) => {
  return useQuery<CourseRecordResponse, AxiosError>({
    queryKey: ['course', params],
    queryFn: () => localGetCourse(params),
    enabled: !!params.id
  });
}
