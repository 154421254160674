import { COURSES_URL } from 'api/urls';
import { client } from 'api/client/client';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

export type DeleteCoursesInput = {
  courseIds: Array<number>
}

const localDeleteCourses = async ({ ...input }: DeleteCoursesInput): Promise<Response> => {
  const response = await client({
    url: `${COURSES_URL}`,
    method: 'DELETE',
    data: input,
  });
  return response.data;
};

export const DeleteCourses =(
  config?: UseMutationOptions<Response, AxiosError, DeleteCoursesInput>
) => {
  return useMutation<Response, AxiosError, DeleteCoursesInput>(localDeleteCourses, config);
}
