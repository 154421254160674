import { client } from 'api/client/client';
import { JOBS_URL } from 'api/urls';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { UploadFile } from 'antd';

export type UploadJobCSVPayload = {
  file: UploadFile;
}

const localUploadJobCSV = async ({ file }: UploadJobCSVPayload): Promise<Response> => {
  const formData = new FormData();
  formData.append('csv', file.originFileObj as any);

  const response = await client({
    url: `${JOBS_URL}/csv`,
    method: 'POST',
    data: formData,
  });
  return response.data;
};

export const UploadJobCSV = (
  config?: UseMutationOptions<Response, AxiosError, UploadJobCSVPayload>
) => {
  return useMutation<Response, AxiosError, UploadJobCSVPayload>(localUploadJobCSV, config);
}
