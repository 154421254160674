import { Rule } from "antd/es/form";

export const formRules: Record<string, Rule[]> = {
    discountCode: [
        {
            min: 1,
            max: 200,
        },
    ],
    offering: [
        {
            required: true,
            min: 1,
            max: 200,
            message: 'Please enter offering name',
        }
    ],
    discountType: [
        {
            required: true,
            message: 'Please select discount type',
        }
    ],
    rank: [
        {
            type: 'number',
            min: 0,
        }
    ],
    description: [
        {
            min: 1,
            max: 500,
        }
    ],
    codeLink: [
        {
            required: true,
            min: 1,
            max: 300,
            type: 'url',
            message: 'Please enter a valid URL',
        }
    ],
    discount: [
        {
            required: true,
            min: 1,
            type: 'number',
            message: 'Please enter discount value',
        }
    ],
    category: [
        {
            required: true,
            message: "Category is required",
        }
    ],
}