import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export type FilterContextType = {
  jobTitle?: string;
  discountName?: string;
  courseName?: string;
  companyName?: string;
  userName?: string;
  updateJobFilter: (name: string) => void,
  updateDiscountFilter: (name: string) => void,
  updateCourseFilter: (name: string) => void,
  updateCompanyFilter: (name: string) => void,
  updateUserFilter: (name: string) => void,
  clearFilters: () => void
}

const FilterContext = createContext<FilterContextType | undefined>(undefined)

const FilterProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const [jobTitle, setJobTitle] = useState<string | undefined>();
  const [discountName, setDiscountName] = useState<string | undefined>();
  const [courseName, setCourseName] = useState<string | undefined>();
  const [companyName, setCompanyName] = useState<string | undefined>();
  const [userName, setUserName] = useState<string | undefined>();


  const clearFilters = () => {
    setJobTitle(undefined);
    setDiscountName(undefined);
    setCourseName(undefined);
    setCompanyName(undefined);
    setUserName(undefined);
  }

  const updateJobFilter = (name: string) => setJobTitle(name);
  const updateDiscountFilter = (name: string) => setDiscountName(name);
  const updateCourseFilter = (name: string) => setCourseName(name);
  const updateCompanyFilter = (name: string) => setCompanyName(name);
  const updateUserFilter = (name: string) => setUserName(name);

  const value = useMemo(() => ({
    jobTitle,
    discountName,
    courseName,
    companyName,
    userName,
    updateJobFilter,
    updateDiscountFilter,
    updateCourseFilter,
    updateCompanyFilter,
    updateUserFilter,
    clearFilters
  }), [companyName, courseName, discountName, jobTitle, userName])

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
}

const useFilterContext = () => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error(
      'useFilterContext must be used within FilterContext'
    )
  }

  return context
}

export { useFilterContext, FilterProvider }
