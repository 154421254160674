import { Rule } from "antd/es/form";

export const formRules: Record<string, Rule[]> = {
    companyName: [
        {
            required: true,
            message: "Company name is required",
        }
    ],
    companyOverview: [
        {
            min: 1,
            max: 5000,
            required: false,
            message: "Company overview must be between 1 and 5000 characters",
        }
    ],
    city: [
        {
            required: true,
            message: "City is required",
            min: 1,
            max: 200,
        }
    ],
    state: [
        {
            required: true,
            message: "State is required",
            min: 1,
            max: 200,
        }
    ],
    country: [
        {
            required: true,
            message: "Country is required",
            min: 1,
            max: 200,
        }
    ],
    jobTitle: [
        {
            required: true,
            message: "Job title is required",
            min: 1,
            max: 200,
        }
    ],
    experience: [
        {
            required: true,
            type: "number",
            min: 0,
        }
    ],
    positionLevel: [
        {
            min: 1,
            max: 200,
        }
    ],
    payRateMin: [
        {
            type: "number",
            min: 0,
        }
    ],
    payRateMax: [
        {
            type: "number",
            min: 0,
        }
    ],
    dutiesResponsibilities: [
        {
            min: 1,
            max: 5000,
        }
    ],
    benefits: [
        {
            min: 1,
            max: 5000,
        }
    ],
    hiringManagerMail: [
        {
            type: "email",
            message: "Please enter a valid email address",
            required: true,
        }
    ],
    rank: [
        {
            type: "number",
            min: 0,
        }
    ],
}