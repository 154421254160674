import type { AxiosError } from 'axios';

import { useQuery, UseQueryOptions } from 'react-query';
import { client, getQueryKey } from 'api/client/client';
import { JOBS_URL } from 'api/urls';
import { JobRecordResponse, JobResponse } from 'types/job';

export type JobParams = {
  page?: number;
  limit?: number;
  jobTitle?: string;
};

type Response = {
  count: number;
  jobs: JobResponse[];
}

 const localGetJobs = async({ ...params }: JobParams): Promise<Response> => {
  const response = await client({
    url: JOBS_URL,
    method: 'GET',
    params,
  });
  return response.data.data;
}

export const GetJobs = (
  params: JobParams,
  config?: UseQueryOptions<Response, AxiosError>
)  => {
  const queryKey = [getQueryKey('jobs'), params.page, params.jobTitle];
  return useQuery<Response, AxiosError>(
    queryKey,
    () => localGetJobs(params),
    config,
  );
}

export type JobRecordPrams = { id: number | undefined};


const localGetJob = async({id} : JobRecordPrams): Promise<JobRecordResponse> => {
  const response = await client({
    url: `${JOBS_URL}/${id}`,
    method: 'GET',
  });
  return response.data.data;
}

export const GetJob = (
  params: JobRecordPrams,
) => {
  return useQuery<JobRecordResponse, AxiosError>({
    queryKey: ['job', params],
    queryFn: () => localGetJob(params),
    enabled: !!params.id
});
}
